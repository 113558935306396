import z from 'zod'
import { phoneNumberSchema } from '@meprism/shared/src/schemas/util'
import {
  CorporateProfile,
  CorporateProfilePost,
} from '../redux/CorporateProfile/CorporateProfileTypes'

// the address portion of the corporate profile is handled separately
// because it's a pain to integrate autocomplete with the form library.
export const corporateProfileWithoutAddress = z.object({
  numberOfEmployees: z.coerce
    .number({ invalid_type_error: 'Number of employees must be a number' })
    .min(1, 'Company must have at least 1 employee')
    .max(
      100000,
      'If you have more than 100,000 employees, please contact support',
    ),
  name: z.string().nonempty('Company name is required'),
  email: z.string().email(),
  phone: phoneNumberSchema.transform((p, ctx) => {
    if (!p) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Phone number is required',
      })
    }
    return p
  }),
  website: z
    .string()
    .url('Website must be a valid URL or left blank')
    .or(z.literal('')),
})

export type CorporateProfileWithoutAddressType = z.TypeOf<
  typeof corporateProfileWithoutAddress
>

export const asCorporateProfilePost = ({
  name,
  email,
  phone,
  website,
  numberOfEmployees,
}: CorporateProfileWithoutAddressType): Omit<
  CorporateProfilePost,
  'address'
> => ({
  company_name: name,
  email,
  phone,
  url: website ? website : undefined,
  employee_num: numberOfEmployees,
})

const defaultCorporateProfileFormState = {
  name: '',
  email: '',
  phone: '',
  website: '',
} as CorporateProfileWithoutAddressType

export const fromCorporateProfileSlice = (
  profile: CorporateProfile | undefined,
): CorporateProfileWithoutAddressType => {
  if (!profile) {
    return defaultCorporateProfileFormState
  }

  const { companyName, phone, website, email, numberOfEmployees } = profile

  return {
    name: companyName,
    phone: phone.replace(/^\+1/, ''),
    website: website || '',
    email,
    numberOfEmployees,
  }
}
