const MpTheme = {
  fontSize: {
    xlarge: 32,
    large: 24, // UPDATED
    medium: 20,
    small: 16,
    xsmall: 14,
    xxsmall: 12,
    xxxsmall: 10,
  } as const,
  fontStyle: {
    medium: 'medium',
    normal: 'normal',
    bold: 'bold',
    light: 'light',
    regular: 'regular',
    semibold: 'semibold', // ADDED
    extrabold: 'extrabold', // ADDED
  } as const,
  fontWeight: {
    normal: '200',
    thin: '100',
    ultraLight: '200',
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    heavy: '800',
    black: '900',
  } as const,
  colors: {
    secondary: {
      default: '#9135E0',
      600: '#742AB3',
      700: '#572086',
      800: '#3A155A',
      900: '#1D0B2D',
      400: '#A75DE6',
      300: '#BD86EC',
      200: '#D3AEF3',
      100: '#E9D7F9',
      50: '#F4EBFC',
    },
    primary: {
      default: '#426BF0',
      600: '#3556C0',
      700: '#284090',
      800: '#1A2B60',
      900: '#0D1530',
      400: '#6889F3',
      300: '#8EA6F6',
      200: '#B3C4F9',
      100: '#D9E1FC',
      50: '#ECF0FE',
    },
    white: '#FFFFFF',
    gray: {
      default: '#757575',
      600: '#5E5E5E',
      700: '#464646',
      800: '#2F2F2F',
      900: '#171717',
      400: '#919191',
      300: '#ACACAC',
      200: '#C8C8C8',
      100: '#E3E3E3',
      50: '#F1F1F1',
    },
    tertiary: {
      pink: {
        default: '#CD1D89',
        600: '#A41794',
        700: '#7B116F',
        800: '#520C4A',
        900: '#290625',
        400: '#D74AC7',
        300: '#E177D5',
        200: '#EBA5E3',
        100: '#F5D2F1',
        50: '#FAE8F8',
      },
      cyan: {
        default: '#2197F5',
        600: '#1A79C4',
        700: '#145B93',
        800: '#0C3C62',
        900: '#031E31',
        400: '#33A6F7',
        300: '#66C1F9',
        200: '#99D5FB',
        100: '#CCEAFD',
        50: '#E6F5FE',
      },
      green: {
        default: '#1ECC98',
        600: '#A41794',
        700: '#7B116F',
        800: '#520C4A',
        900: '#290625',
        400: '#4BD6AD',
        300: '#78E0C1',
        200: '#A5EBD6',
        100: '#D2F5EA',
        50: '#E9FAF5',
      },
    },
    informational: { default: '#426BF0', light: '#F1F4FE', dark: '#284090' }, // UPDATED -> Can we do shades like in the web theme?
    success: { default: '#3DA010', light: '#ECFFF1', dark: '#005418' }, // UPDATED -> Can we do shades like in the web theme?
    warning: { default: '#E66000', light: '#FFF3EB', dark: '#5C2600' }, // UPDATED -> Can we do shades like in the web theme?
    error: { default: '#FF3D00', light: '#FFF3F0', dark: '#541400' }, // UPDATED -> Can we do shades like in the web theme?
  },
  gradients: {
    primary: ['#426BF0', '#9135E0'],
    white: ['#ECF0FE', '#FFFFFF'],
    gray: ['#757575', '#DFDFDF'],
    black: ['#464646', '#171717'],
    secondary: ['#73B959', '#93DB79'],
    tertiary: ['#DF3434', '#F33333'],
  },
  fonts: {
    plusJakartaSans: 'PlusJakartaSans-Regular',
    plusJakartaSansRegular: 'PlusJakartaSans-Regular',
    plusJakartaSansSemibold: 'PlusJakartaSans-Semibold',
    plusJakartaSansExtrabold: 'PlusJakartaSans-Extrabold',
    courierNew: 'Courier New',
  } as const,
  spacing: {
    xsmall: 4,
    small: 6,
    medium: 8,
    large: 10,
    xlarge: 12,
    xxlarge: 13,
  },
  border: {
    small: 1,
    medium: 3,
    large: 5,
  },
  lineSpacing: {
    none: 0,
    xxsmall: 0.2,
    xsmall: 0.4,
    small: 0.6,
    medium: 0.8,
    large: 1,
    xlarge: 1.4,
  },
  lineHeight: {
    xsmall: 15,
    small: 18,
    h2: 21,
    medium: 24, // UPDATED
    large: 30, // UPDATED
    xlarge: 40, // UPDATED
  },
  radius: {
    none: 0,
    shadow: 6,
    small: 10,
    medium: 18,
    large: 100,
  },
  layouts: {
    widths: {
      sm: '466px',
      md: '710px',
    },
  },
}
export default MpTheme
