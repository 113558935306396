import React, { useState } from 'react'
import {
  useMediaQuery,
  ThemeProvider,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ButtonBase,
  Menu,
} from '@mui/material'
import { fonts, theme } from '../../../config/Dashboardtheme'
import Pagination from '@mui/material/Pagination'
import {
  InvitedAndActiveEmployeesSelector,
  resendInvite,
} from '../../../redux/corporateService'
import MpSvgIcon from './MpSvgIcon'
import { useAppDispatch } from '../../../redux/storeExports'
import ConfirmationModal from './ConfirmationModal'

const UserManagementListItem = () => {
  const [open, setOpen] = useState(false)
  const filteredEmployees = InvitedAndActiveEmployeesSelector()
  const rows = filteredEmployees
    .map((item, index) => ({
      id: index + 1,
      col1: item.email,
      col2: item.invite_status,
      col6: item.invite_time,
      col5: item.muid,
    }))
    .filter((value, index, self) => {
      return self.findIndex((item) => item.col1 === value.col1) === index
    })

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedStatus, setSelectedStatus] = useState('')
  const [page, setPage] = useState(0)
  const rowsPerPage = 10

  const handleMenuItemClick = (value: React.SetStateAction<string>) => {
    setSelectedStatus(value)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1)
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const isOpen = Boolean(anchorEl)
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const dispatch = useAppDispatch()
  const [openMenuIndex, setOpenMenuIndex] = React.useState<number | null>()
  const [muidrow,setMuidRow]= React.useState<string | null>()
  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    if (openMenuIndex === index) {
      setOpenMenuIndex(null);
      setAnchorEl(null);
    } else {
      setOpenMenuIndex(index);
      setAnchorEl(event.currentTarget);
      const clickedRow = rows.find((row) => row.id === index);
      setMuidRow(clickedRow?.col5 || null);
    }
  };
  

  const close = () => {
    setAnchorEl(null)
    setOpenMenuIndex(null)
    buttonRef.current?.focus()
  }
  const handleopen = () => {
    setOpen(true)
  }

  const columns = [
    { key: 'col1', name: 'Email ID' },
    {
      key: 'col2',
      name: 'Status',
      width: 90,
      formatter: (props: any) => (
        <span
          style={{
            color: props.row.col2 === 'accepted' ? '#27AE60' : '#EB5757',
          }}>
          {props.row.col5}
        </span>
      ),
    },
    {
      key: 'col3',
      name: '',
      width: 100,
      headerRenderer: () => (
        <Select
          value={selectedStatus}
          onChange={(e) => handleMenuItemClick(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Select status' }}>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="accepted">Accepted</MenuItem>
          <MenuItem value="">All</MenuItem>
        </Select>
      ),
    },

    {
      key: 'col4',
      name: '',
      width: 20,
      formatter: (props: any) => (
        <Box
          sx={{
            backgroundColor:
              openMenuIndex === props.row.id ? '#ECF0FD' : 'white',
            borderRadius: '2px',
          }}
          key={props.row.col1}>
          {props.row.col2 !== 'deleted' && (
            <ButtonBase
              sx={{ borderRadius: '10px' }}
              type="button"
              onClick={(event) => handleButtonClick(event, props.row.id)}
              ref={buttonRef}
              aria-controls={isOpen ? 'simple-menu' : undefined}
              aria-expanded={isOpen || undefined}
              aria-haspopup="menu">
              <MpSvgIcon
                icon={
                  openMenuIndex === props.row.id ? 'threeDotsBlue' : 'threeDotsNew'
                }
              />
            </ButtonBase>
          )}
          <Menu
            open={openMenuIndex === props.row.id}
            onClose={close}
            anchorEl={anchorEl}
            sx={{ borderRadius: '20px', '& .MuiList-root.MuiMenu-list': { backgroundColor: '#6F8EF51A' }}}
            PaperProps={{
              style: {
                opacity: 'inherit',
                backgroundColor: '#fff', // Set the background color here
              },
            }}>

            <MenuItem
              onClick={() => {
                handleopen()
                close()
              }}>
              <Typography variant={'label'} color={'#1A1B20'}>
                Remove Employee
              </Typography>
            </MenuItem>
            {props.row.col2 === 'invited' && (
              <MenuItem
                disabled={!props.row.col5}
                onClick={() => {
                  dispatch(resendInvite(props.row.col5 as string))
                }}>
                <Typography variant={'label'} color={'#1A1B20'}>
                  Resend Invitation
                </Typography>
              </MenuItem>
            )}
          </Menu>
          <ConfirmationModal
            open={open}
            setOpen={setOpen}
            muid={muidrow}
          />
        </Box>
      ),
    },
  ]

  const filteredRows = selectedStatus
    ? rows.filter((row) => row.col2 === selectedStatus)
    : rows
  return (
    <ThemeProvider theme={theme}>
      {!isMobile ? (
        <Box
          style={{ height: 'auto', width: '100%', paddingBottom: '16px' }}
          bgcolor="#fff"
          borderRadius={5}
          p={3}>
          <TableContainer>
            <Table
              style={{
                background: '#fff',
                color: '#040115',
                borderRadius: 5,
                padding: 3,
              }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.key}
                      style={{
                        width: column.width,
                        borderBottom: '1.5px solid #426BF0',
                        ...fonts.Inter.SemiBold,
                      }}>
                      {column.name}
                      {column.key === 'col3' && (
                        <Select
                          value={selectedStatus}
                          onChange={(e) => handleMenuItemClick(e.target.value)}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Select status' }}
                          style={{ marginLeft: '8px', width: '120px' }}>
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value={"invited"||'queued'}>Invited</MenuItem>
                          <MenuItem value="accepted">Active</MenuItem>
                        </Select>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.key}
                          style={{ borderBottom: '1.5px solid #615F6B' }}>
                          {column.key === 'col2' ? (
                            <span
                              style={{
                                color:
                                  row.col2 === 'accepted'
                                    ? '#27AE60'
                                    : '#EB5757',
                              }}>
                              {row.col2 === 'accepted'
                                ? 'Active'
                                : row.col2 === 'queued'
                                ? 'Invited'
                                : 'Invited'}
                            </span>
                          ) : column.formatter ? (
                            column.formatter({ row })
                          ) : (
                            row[column.key as keyof typeof row]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                padding: 2,
                borderRadius: 5,
                background: '#fff',
              }}>
              <Pagination
                variant="outlined"
                shape="rounded"
                count={Math.ceil(filteredRows.length / rowsPerPage)}
                page={page + 1}
                onChange={handleChangePage}
              />
            </Box>
          </TableContainer>
        </Box>
      ) : (
        <Box
          style={{ height: 'auto', width: '100%' }}
          bgcolor="#fff"
          borderRadius={5}
          p={3}>
          {rows.map((row) => (
            <Grid
              key={row.id}
              container
              direction="column"
              spacing={2}
              sx={{
                borderBottom: '1px solid rgba(97, 95, 107, 0.5)',
                padding: 2,
                position: 'relative',
              }}>
              <Box
                sx={{
                  backgroundColor:
                    openMenuIndex === row.id ? '#ECF0FD' : 'white',
                  borderRadius: '2px',
                  position: 'absolute',
                  top: '0',
                  mt: 5,
                  right: '0',
                }}
                key={row.id}>
                {row.col2 !== 'deleted' && (
                  <ButtonBase
                    sx={{ borderRadius: '10px' }}
                    type="button"
                    onClick={(event) => handleButtonClick(event, row.id)}
                    ref={buttonRef}
                    aria-controls={isOpen ? 'simple-menu' : undefined}
                    aria-expanded={isOpen || undefined}
                    aria-haspopup="menu">
                    <MpSvgIcon
                      icon={
                        openMenuIndex === row.id ? 'threeDotsBlue' : 'threeDotsNew'
                      }
                    />
                  </ButtonBase>
                )}
                <Menu
                  open={openMenuIndex === row.id}
                  onClose={close}
                  anchorEl={anchorEl}
                  PaperProps={{
                    style: {
                      backgroundColor: 'rgba(111, 142, 245, 0.10)',
                    },
                  }}
                  sx={{ borderRadius: '20px', opacity: 1 }}>
                  <MenuItem
                    onClick={() => {
                      handleopen()
                      close()
                    }}>
                    <Typography variant={'label'} color={'#1A1B20'}>
                      Remove Employee
                    </Typography>
                  </MenuItem>
                  {row.col2 === 'invited' && (
                    <MenuItem
                      disabled={!row.col5}
                      onClick={() => {
                        dispatch(resendInvite(row.col5 as string))
                      }}>
                      <Typography variant={'label'} color={'#1A1B20'}>
                        Resend Invitation
                      </Typography>
                    </MenuItem>
                  )}
                </Menu>
                <ConfirmationModal
                  open={open}
                  setOpen={setOpen}
                  muid={muidrow}
                />
              </Box>
              {columns
                .filter((col) => col.key !== 'col6' && col.key !== 'col5')
                .map((col) => (
                  <>
                    <Grid item key={col.key} xs={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'left',
                          wordWrap: 'break-word',
                        }}>
                        <Typography variant="h5">{col.name}</Typography>
                        <Typography variant="label">
                          {col.key === 'col2' ? (
                            <span
                              style={{
                                color:
                                  row.col2 === 'accepted'
                                    ? '#27AE60'
                                    : '#EB5757',
                              }}>
                              {row.col2 === 'accepted'
                                ? 'Active'
                                : row.col2 === 'queued'
                                ? 'Invited'
                                : 'Invited'}
                            </span>
                          ) : (
                            row[col.key as keyof typeof row]
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                ))}
            </Grid>
          ))}
        </Box>
      )}
    </ThemeProvider>
  )
}

export default UserManagementListItem
