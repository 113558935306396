import Typography, { TypographyProps } from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import React from 'react'
import MpSvgIcon, { MpSvgIconProps } from '../atom/MpSvgIcon'

interface MpIconAndTextProps extends MpSvgIconProps {
  text: string
  textImageSpace: number
  typographyProps?: TypographyProps
  boxProps?: BoxProps
}
const MpIconAndText = (props: MpIconAndTextProps) => {
  const { text, typographyProps, textImageSpace, boxProps, ...otherProps } =
    props
  return (
    <Box display="flex" alignItems="center" {...boxProps}>
      <MpSvgIcon {...otherProps} />
      <Typography ml={`${textImageSpace}px`} sx={{color:"#ffffff"}}{...typographyProps}>
        {text}
      </Typography>
    </Box>
  )
}

export default MpIconAndText
