import { Box, Button, Skeleton, Typography, useTheme } from '@mui/material'
import React, { Suspense } from 'react'
import {
  CorporateActions,
  InvitedAndActiveEmployeesSelector,
  PriceSelector,
  RemovedEmployeesSelector,
} from '../../../redux/corporateService'
import { useAppDispatch } from '../../../redux/storeExports'
import EmailListItem from '../atom/EmailListItem'
import MpSvgIcon from '../atom/MpSvgIcon'
import { Await, useRouteLoaderData } from 'react-router-dom'
import RequireCorporateCode from '../../gateways/RequireCorporateCode'

interface EmailListProps {
  removed?: boolean
}

const EmailList = (props: EmailListProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const filteredEmployees = props.removed
    ? RemovedEmployeesSelector()
    : InvitedAndActiveEmployeesSelector()
  const price = PriceSelector()
  if (filteredEmployees.length === 0) {
    return (
      <Box
        sx={{
          bgcolor: '#f9f9f9',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '117px',
          paddingBottom: '117px',
          paddingLeft: '64px',
          paddingRight: '64px',
        }}>
        <MpSvgIcon icon={'invite'} color={theme.palette.primary.main} />
        <Typography variant={'body1'} sx={{ textAlign: 'center', mt: '10px' }}>
          Invite employees to begin taking advantage of their benefits
        </Typography>
        <Typography
          variant={'caption'}
          sx={{
            textAlign: 'center',
            mt: '10px',
            color: '#3A155A',
          }}>
          Upload a .CSV containing employee email addresses or enter addresses
          separately{' '}
        </Typography>
        <Typography
          variant={'body1'}
          sx={{
            textAlign: 'center',
            mb: '40px',
            mt: '10px',
            color: '#3A155A',
          }}>
          ${price} / employee / year
        </Typography>
        <Button
          variant={'contained'}
          onClick={() => dispatch(CorporateActions.setModalOpen(true))}>
          Get Started
        </Button>
      </Box>
    )
  } else {
    return (
      <>
        {filteredEmployees.map((employeeObj, index) => (
          <EmailListItem employee={employeeObj} key={index} />
        ))}
      </>
    )
  }
}

const EmailItemSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '24px',
        paddingBottom: '24px',
        justifyContent: 'space-between',
        alignItems: 'center',
        pl: '10px',
        pr: '10px',
        width: '100%',
      }}>
      <Skeleton sx={{ width: '100%', minHeight: '40px' }} />
    </Box>
  )
}

const EmailListSkeleton = () => {
  return (
    <>
      {[0, 0, 0, 0, 0].map((_, index) => (
        <EmailItemSkeleton key={index} />
      ))}
    </>
  )
}

const EmailListSuspense = (props: EmailListProps) => {
  const loaderData = useRouteLoaderData('main') as any
  return (
    <RequireCorporateCode>
      <Suspense fallback={<EmailListSkeleton />}>
        <Await
          resolve={loaderData.users}
          errorElement={<EmailList {...props} />}>
          <EmailList {...props} />
        </Await>
      </Suspense>
    </RequireCorporateCode>
  )
}

export default EmailListSuspense
