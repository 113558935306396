import React, { forwardRef } from 'react'

import { InputBaseComponentProps } from '@mui/material'
import { IMaskInput } from 'react-imask'

const phoneMask = '(#00) 000-0000'

type PhoneNumberMaskedInputProps = InputBaseComponentProps & {
  name: string
  onChange: (event: { target: { name: string; value: string } }) => void
}

export const PhoneNumberMaskedInput = forwardRef<
  HTMLElement,
  PhoneNumberMaskedInputProps
>(function PhoneNumberMasked(props, ref) {
  const { onChange, ...rest } = props

  return (
    <IMaskInput
      {...rest}
      lazy={false}
      mask={phoneMask}
      definitions={{
        '#': /[1-9]/,
      }}
      placeholderChar={'#'}
      onAccept={(value) =>
        onChange({ target: { name: props.name, value: value as string } })
      }
      inputRef={ref as any}
    />
  )
})
