import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { PieChart, Pie, Cell } from 'recharts'
import { useMediaQuery, Grid, Box } from '@mui/material'
import { theme } from '../../../config/Dashboardtheme'

interface PieChartCardProps {
  title: string
  data: { name: string; value: number }[]
  colors: string[]
  icon1: string
  icon2: string
  bgcolor: string
  title1: string
  title2: string
  totalcount1: number
  totalcount2: number
}

const CustomPieChart: React.FC<{
  data: { name: string; value: number }[]
  colors: string[]
}> = ({ data, colors }) => (
  <PieChart width={187} height={145}>
    <Pie data={data} cx={70} cy={70} outerRadius={70} dataKey="value">
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[index]} />
      ))}
    </Pie>
  </PieChart>
)

const PieChartCard: React.FC<PieChartCardProps> = ({
  title,
  data,
  colors,
  icon1,
  bgcolor,
  title1,
  title2,
  icon2,
  totalcount1,
  totalcount2,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Card sx={{ borderRadius: 5 }}>
      <CardContent>
        <Typography variant="h3" sx={{ mb: 5 }}>
          {title}
        </Typography>
        <Grid container spacing={isMobile ? 3 : 0} alignItems="center">
          {/* Left side with Pie Chart */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ ml: isMobile ? 5 : 0 }}>
              <CustomPieChart data={data} colors={colors} />
            </Box>
          </Grid>

          {/* Right side with text */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              alignItems="center"
              mb={2}
              justifyContent="space-between"
              p={2}
              bgcolor={bgcolor}
              borderRadius={2}>
              <Box display="flex" alignItems="center">
                <Box
                  p={2}
                  bgcolor={colors[0]}
                  borderRadius={2}
                  alignItems="center"
                  mr={1}>
                  <img
                    src={icon1}
                    alt="icon1"
                    width={18}
                    height={18}
                    style={{ display: 'block', margin: 'auto' }}
                  />
                </Box>
                <Typography variant="h4" ml={2}>
                  {title1}
                </Typography>
              </Box>
              {/* Corner number */}
              <Typography variant="h2">{totalcount1}</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
              bgcolor={bgcolor}
              borderRadius={2}>
              <Box display="flex" alignItems="center">
                {/* Icon */}
                <Box
                  p={2}
                  bgcolor={colors[1]}
                  borderRadius={2}
                  alignItems="center"
                  mr={1}>
                  <img
                    src={icon2}
                    alt="icon1"
                    width={18}
                    height={18}
                    style={{ display: 'block', margin: 'auto' }}
                  />
                </Box>

                {/* Text */}
                <Typography variant="h4" ml={2}>
                  {title2}
                </Typography>
              </Box>
              {/* Corner number */}
              <Typography variant="h2">{totalcount2}</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PieChartCard
