import {
  Container,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
} from '@mui/material'
import React from 'react'
import PieChartCard from '../shared/atom/PieChartCard'
import scan from '@meprism/shared/src/assets/icons/scan.svg'
import user from '@meprism/shared/src/assets/icons/participating.svg'
// import shield from '@meprism/shared/src/assets/icons/shield-tick.svg'
// import { theme } from '../../config/Dashboardtheme'
// import ScannedSites from '../shared/atom/ScannedSites'
import { SelectCorporateCode, UserStatusSelector } from '../../redux/corporateService'
import DashboardGrid from '../shared/molecule/DashboardGrid'
import MpSvgIcon from '../shared/atom/MpSvgIcon'
import MainHeader from '../shared/organism/MainHeader'

const DashboardView = () => {
  const UserStatus = UserStatusSelector()
  const corporateCode = SelectCorporateCode()
  const removing =
    UserStatus.total_employees_brokers_in_new +
    UserStatus.total_employees_brokers_in_removing
  const removed = UserStatus.total_employees_brokers_in_removed
  const participating = UserStatus.total_participating_employees
  const pending = UserStatus.total_pending_employees
  const removaldata = [
    { name: 'Removing', value: removing },
    { name: 'Removed', value: removed },
  ]
  const invitedata = [
    { name: 'Pending', value: pending },
    { name: 'Participating', value: participating },
  ]

  const colors = ['#C67EFF', '#426BF0']
  const colors2 = ['#E2B93B', '#27AE60']
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Container fixed maxWidth={'lg'} sx={{ mb: 16, mt: '12px' }}>
        <MainHeader title='Dashboard'/>
      {/* {!isMobile && (
        <Box mb={5}>
          <ScannedSites count={'91'} icon={shield} title="Sites Scanned" />
        </Box>
      )} */}

      <Grid container columnSpacing={5} rowSpacing={5} mt={5}>
        <Grid item xs={12} md={6}>
          {corporateCode ? (
            <PieChartCard
              title="Removal Status"
              data={removaldata}
              colors={colors2}
              icon1={user}
              bgcolor={''}
              title1={'Removing'}
              title2={'Removed'}
              icon2={scan}
              totalcount1={removing}
              totalcount2={removed}
            />
          ) : (
            <Card sx={{ borderRadius: 5 }}>
              <CardContent sx={{ display: 'grid', placeItems: 'center' }}>
                <MpSvgIcon
                  icon={'hourglassTwoTone'}
                  color="#4B6AE8"
                  height={80}
                  width={80}
                />
                <Typography variant={'h3'} sx={{ my: 5 }}>
                  {' '}
                  Configuring Your Account
                </Typography>
                <Typography>
                  You will receive an email within 1 business day once your
                  account has been configured.
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChartCard
            title="Participant Stats"
            data={invitedata}
            colors={colors}
            icon1={user}
            bgcolor={'#C67EFF33'}
            title1={'Pending Invitations'}
            title2={'Participating'}
            icon2={scan}
            totalcount1={pending}
            totalcount2={participating}
          />
        </Grid>
      </Grid>
      <Box mt={5}>
        <DashboardGrid />
      </Box>
    </Container>
  )
}

export default DashboardView
