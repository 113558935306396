import React, { useContext, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { AnalyticsService } from '../../services/AnalyticsService'
import { AuthLoader } from '../gateways/AuthLoader'
import { Box, Button, Stack, Typography } from '@mui/material'
import { AuthContext } from './AuthContext'
import PasswordField from '../shared/atom/PasswordField'
import ValidationHint from '../shared/atom/MpValidationHint'
import { Auth } from '@aws-amplify/auth'
import { minPasswordLength, validatePasswords } from './config'

export const CompletePassword = () => {
  const [errorText, setErrorText] = useState<string>('')
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const location = useLocation()
  const from = (location?.state as any)?.from || '/'
  // IDK why AWS types are just dead wrong here
  // @ts-ignore
  const email = authContext.user?.challengeParam?.userAttributes?.email

  const onSubmit = async () => {
    try {
      await Auth.completeNewPassword(authContext.user, password)
      navigate(from, { replace: true })
    } catch (error) {
      switch (error?.name) {
        case 'InvalidUsername':
          setErrorText(
            'That is not a valid registered email. Please try again with a different address.',
          )
          break
        case 'NotAuthorizedException':
          if (error.message.includes('expired')) {
            setErrorText('Your session has expired')
          } else {
            setErrorText(
              'There was an error processing your request. Please try again later.',
            )
          }
          break
        case 'LimitExceededException':
          setErrorText(
            'You have made too may attempts - please wait and try again',
          )
          break
        default:
          setErrorText(
            'There was an error processing your request. Please try again later.',
          )
          AnalyticsService.error(`Unhandled Authorization error: `, error)
          break
      }
    }
  }

  const [shouldDisplayPasswordInfo, setShouldDisplayPasswordInfo] =
    useState<boolean>(false)
  const [shouldShowMatchingPasswords, setShouldShowMatchingPasswords] =
    useState<boolean>(false)
  const { hasSufficientLength, hasSymbol, hasMatchingPassword, hasUpperCase } =
    validatePasswords(password, confirmPassword)
  const isPasswordValid =
    hasSufficientLength && hasUpperCase && hasSymbol && hasMatchingPassword

  if (!authContext.user) {
    return <Navigate to={'/login'} replace />
  }

  return (
    <AuthLoader>
      <Stack
        spacing={4}
        sx={{
          maxWidth: 'sm',
          mx: 'auto',
          mb: 8,
        }}>
        <Typography my={1} variant={'body1'}>
          Enter a new password for {email}
        </Typography>
        <PasswordField
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          onFocus={() => {
            setShouldDisplayPasswordInfo(true)
          }}
          label={'Password'}
          variant={'outlined'}
          sx={{
            margin: 1,
          }}
          autoComplete="new-password"
        />
        <PasswordField
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          onFocus={() => {
            setShouldShowMatchingPasswords(true)
          }}
          label={'Confirm Password'}
          variant={'outlined'}
          sx={{
            margin: 1,
          }}
          autoComplete="new-password"
        />
        <Box sx={{ alignItems: 'center' }}>
          {shouldDisplayPasswordInfo && (
            <Box>
              <ValidationHint
                isValid={hasSufficientLength}
                text={`At least ${minPasswordLength} characters`}
              />
              <ValidationHint
                isValid={hasUpperCase}
                text={'Contains at least one upper case letter'}
              />
              <ValidationHint
                isValid={hasSymbol}
                text={'Contains number or symbol'}
              />
              {shouldShowMatchingPasswords && (
                <ValidationHint
                  isValid={hasMatchingPassword}
                  text={'Passwords match'}
                />
              )}
            </Box>
          )}
        </Box>
        <Box>
          <Typography color={'error'}>{errorText}</Typography>
        </Box>
        <Box
          sx={{
            justifySelf: 'flex-end',
            mt: 1,
            mb: 2,
          }}>
          <Button
            variant={'contained'}
            disabled={!isPasswordValid}
            sx={{
              mt: 'auto',
              minWidth: '180px',
            }}
            onClick={onSubmit}>
            Complete Password
          </Button>
        </Box>
      </Stack>
    </AuthLoader>
  )
}
