import {
  Box,
  ButtonBase,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import React from 'react'
import {
  CorporateEmployee,
  fetchUsers,
  removeUser,
  resendInvite,
} from '../../../redux/corporateService'
import { useAppDispatch } from '../../../redux/storeExports'
import { fonts } from '../../../theme/theme'
import MpSvgIcon from './MpSvgIcon'
import { prettyPrintTimeSince } from '@meprism/shared/src/utils/dateHelpers'

interface EmailListItemProps {
  employee: CorporateEmployee
}

const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

const EmailListItem = (props: EmailListItemProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const isOpen = Boolean(anchorEl)
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const dispatch = useAppDispatch()

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isOpen) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const close = () => {
    setAnchorEl(null)
    buttonRef.current?.focus()
  }

  const onDelete = async () => {
    await dispatch(removeUser(props.employee.muid as string)).unwrap()
    dispatch(fetchUsers())
  }

  return (
    <Box
      sx={{
        backgroundColor: isOpen ? '#ECF0FD' : 'white',
        borderRadius: '2px',
      }}
      key={props.employee.email}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '24px',
          paddingBottom: '24px',
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: '10px',
          pr: '10px',
        }}>
        <Typography variant={'body2'} sx={{ fontFamily: fonts.Jakarta[400] }}>
          {props.employee.email}
        </Typography>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {(props.employee.invite_status === 'invited' ||
            props.employee.invite_status === 'queued') &&
            props.employee.invite_time && (
              <Typography
                sx={{
                  fontFamily: fonts.Jakarta[400],
                  fontStyle: 'italic',
                  mr: '5px',
                }}
                style={{ fontSize: '12px' }}>
                Invited{' '}
                {prettyPrintTimeSince(new Date(props.employee.invite_time))}
              </Typography>
            )}
          {(props.employee.invite_status === 'deleted' ||
            props.employee.invite_status === 'failed' ||
            props.employee.invite_status === 'expired') && (
            <Typography
              sx={{
                fontFamily: fonts.Jakarta[400],
                fontStyle: 'italic',
                mr: '5px',
                color: 'red',
              }}
              style={{ fontSize: '12px' }}>
              {capitalizeFirstLetter(props.employee.invite_status)}
            </Typography>
          )}
          {props.employee.invite_status !== 'deleted' && (
            <ButtonBase
              sx={{ borderRadius: '10px' }}
              type="button"
              onClick={handleButtonClick}
              ref={buttonRef}
              aria-controls={isOpen ? 'simple-menu' : undefined}
              aria-expanded={isOpen || undefined}
              aria-haspopup="menu">
              <MpSvgIcon icon={isOpen ? 'threeDotsBlue' : 'threeDots'} />
            </ButtonBase>
          )}
          <Menu
            open={isOpen}
            onClose={close}
            anchorEl={anchorEl}
            sx={{ borderRadius: '20px' }}>
            <MenuItem onClick={() => onDelete()}>
              <Typography variant={'label'} color={'#ff3D00'}>
                Remove Employee
              </Typography>
            </MenuItem>
            {props.employee.invite_status === 'invited' && (
              <MenuItem
                disabled={!props.employee.muid}
                onClick={() => {
                  dispatch(resendInvite(props.employee.muid as string))
                }}>
                <Typography variant={'label'} color={'#426BF0'}>
                  Resend Invitation
                </Typography>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}

export default EmailListItem
