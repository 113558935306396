import { Box, Skeleton, Typography, useTheme } from '@mui/material'
import React, { PropsWithChildren, Suspense, useEffect } from 'react'
import MpSvgIcon from '../shared/atom/MpSvgIcon'
import { fetchCode, SelectCorporateCode } from '../../redux/corporateService'
import { Await, useRouteLoaderData } from 'react-router-dom'
import { useAppDispatch } from '../../redux/storeExports'

export const WaitCard = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const interval = setInterval(() => dispatch(fetchCode()), 10000)
    return () => clearInterval(interval)
  })

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        backgroundColor: 'grey.50',
        borderRadius: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        py: 30,
        px: 20,
      }}>
      <MpSvgIcon
        icon={'hourglassTwoTone'}
        color={theme.palette.primary.main}
        height={80}
        width={80}
      />
      <Typography variant={'h2'} sx={{ my: 5 }}>
        {' '}
        Configuring Your Account
      </Typography>
      <Typography variant={'body2'}>
        You will receive an email within 1 business day once your account has
        been configured.
      </Typography>
    </Box>
  )
}

const RequireCorporateCode = (props: PropsWithChildren) => {
  const corporateCode = SelectCorporateCode()

  if (!corporateCode) {
    return <WaitCard />
  }

  return <>{props.children}</>
}

const CardSkeleton = () => (
  <Skeleton
    variant={'rounded'}
    width={'100%'}
    height={400}
    sx={{ borderRadius: '20px' }}
  />
)

const RequireCorporateCodeWrapper = (props: PropsWithChildren) => {
  const loaderData = useRouteLoaderData('main') as any

  return (
    <Suspense fallback={<CardSkeleton />}>
      <Await
        resolve={loaderData.corporateCode}
        errorElement={
          <RequireCorporateCode>{props.children}</RequireCorporateCode>
        }>
        <RequireCorporateCode>{props.children}</RequireCorporateCode>
      </Await>
    </Suspense>
  )
}

export default RequireCorporateCodeWrapper
