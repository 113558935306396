import React from 'react'
import {  Grid, ThemeProvider, useMediaQuery } from '@mui/material'
import SignInSideLeft from '../shared/organism/SignInLeftLayout'
import { theme } from '../../config/SigninTheme'
import SignInSideRight from '../shared/organism/SignInRightLayout'

export const SignInLayout = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: '100vh',[theme.breakpoints.up('sm')]: {
      
    },}}>
        {isMobile ? (
            <>
              <SignInSideRight />
              <SignInSideLeft />
            </>
          ) : (
            <>
              <SignInSideLeft />
              <SignInSideRight />
            </>
           )}
        </Grid>
      </ThemeProvider>
    </>
  )
}
