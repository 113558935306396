// @TODO: Fill this
export const MEPRISM_LINKS = {
  meprismBusinessCta: 'https://meprism.com/business',
  mePrismsignin:'https://meprism.com/login/signin',
  mePrismsignup:'https://meprism.com/login/create',
}

export const MEPRISM_PRICING_INFO = {
  ANNUAL_EMPLOYEE_COST_DOLLARS: 100,
}
