import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'
import background from '../../assets/images/SuccessBG.png'
import Typography from '@mui/material/Typography'
import logo from '@meprism/shared/src/assets/icons/logos/mePrism_business.svg'
import {
  Button,
  List,
  ListItem,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material'
import MpReTheme, { fonts, theme } from '../../config/SigninTheme'

function SignUpSuccess() {
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}

      <Grid
      item
      xs={false}
      sm={4}
      md={7}
      sx={{
        height:'auto',
        minHeight:'100vh',
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        p: 8,
      }}>
        <Box
          sx={{ maxWidth: !isMobile ? MpReTheme.Boxwidth.widths.sm : 'unset' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'left',
            }}>
            {' '}
            <List>
              <ListItem>
                <img src={logo} alt="Logo" style={{ width: 200, height: 42 }} />
              </ListItem>
              <ListItem>
                {' '}
                <Typography variant="h1" sx={{ marginTop: 10 }}>
                  <span style={{ color: '#426BF0' }}>
                    Thank you for choosing
                  </span>{' '}
                  mePrism Privacy
                  <span style={{ color: '#426BF0' }}> to help</span> protect
                  your Business
                </Typography>
              </ListItem>
              <ListItem>
                {' '}
                <Typography variant="h3">
                  We need to take care of some things on our end to configure
                  your account. We will send you a confirmation email once your
                  account is ready.
                </Typography>
              </ListItem>
              <ListItem>
                {' '}
                <Typography variant="body2">
                  While you wait, start preparing to add your teammates to
                  mePrism for Business. To add member:
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  1. Download our template
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  2. Fill out the template with emails of users you’d like to
                  invite to your account
                </Typography>
              </ListItem>
              <ListItem>
                {' '}
                <Typography variant="body2">
                  3. Save and upload the completed CSV file on the user’s page
                </Typography>
              </ListItem>
              <ListItem sx={{mb:10}}>
                {' '}
                <Typography variant="body2">
                  4. Alternatively, you can also add users later via the user’s
                  page
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: isMobile ? 'flex' : 'unset',
                  flexDirection: isMobile ? 'column' : 'unset',
                  alignItems: isMobile ? 'center' : 'unset',
                }}>
                <a
                  download="mePrismOnboarding.csv"
                  href="/mePrismOnboarding.csv" >
                  <Button
                    variant={'contained'}
                    color={'darkPurple'}
                    size={'large'}
                    sx={{
                      background: '#1A1B20',
                      border: '2px solid  #C67EFF',
                      fontFamily: fonts.Inter.SemiBold,
                      color: '#C67EFF',
                      width: isMobile ? '100%' : '30%',
                      height:!isMobile ? '50px':'50px',
                      marginRight: isMobile ? 'unset' : 10,
                      '&.Mui-disabled': {
                        background: '#426BF0',
                        color: '#C67EFF',
                      },
                      ':hover': {
                        background: '#1A1B20',
                        color: '#C67EFF',
                      },
                    }}>
                    <Typography
                      sx={{
                        color: '#C67EFF',
                        fontFamily: fonts.Inter.SemiBold,
                        fontSize:'18px',
                        '@media (max-width: 600px)': {
                          fontSize: '14px',
                        },
                      }}>
                      Download CSV Template
                    </Typography>
                  </Button>
                </a>
                <Button
                  variant={'contained'}
                  color={'darkPurple'}
                  onClick={() => navigate('/', { replace: true })}
                  sx={{
                    mt:isMobile?5:'unset',
                    background: '#426BF0',
                    fontFamily: fonts.Inter.SemiBold,
                    color: '#ffffff',
                    height: !isMobile?'50px':'50px',
                    width: isMobile ? '80%' : '30%',
                    '&.Mui-disabled': {
                      background: '#426BF0',
                      color: '#ffffff',
                    },
                  }}>
                 I&apos;ll wait
                </Button>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Grid>
    </ThemeProvider>
  )
}

export default SignUpSuccess
