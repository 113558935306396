import React, { PropsWithChildren } from 'react'
import { selectIsAuthLoading } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { useAppSelector } from '../../redux/storeExports'
import { useNavigation } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'

export const AuthLoader = ({ children }: PropsWithChildren) => {
  const authLoading = useAppSelector(selectIsAuthLoading)
  const navigation = useNavigation()

  if (authLoading || navigation.state === 'loading') {
    return (
      <Box
        sx={{
          width: '100%',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <CircularProgress sx={{ alignSelf: 'center', justifySelf: 'center' }} />
      </Box>
    )
  }

  return <>{children}</>
}
