import React, { useState } from 'react'
import PasswordField from '../shared/atom/PasswordField'
import ValidationHint from '../shared/atom/MpValidationHint'
import { signUpWithEmail } from '../../services/AuthenticationService'
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom'
import { AnalyticsService } from '../../services/AnalyticsService'
import Stack from '@mui/material/Stack/Stack'
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { minPasswordLength, validatePasswords } from './config'
import { handleOtpNavigation } from './LoginOTP'
import MpTheme from '../../config/MpTheme'
import { ThemeProvider } from '@emotion/react'
import  { fonts, theme } from '../../config/SigninTheme'

const SignUpEmail = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = (location?.state as any)?.from || '/'
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')

  const [shouldShowMatchingPasswords, setShouldShowMatchingPasswords] =
    useState<boolean>(false)
  const { hasMatchingPassword, hasSymbol, hasSufficientLength, hasUpperCase } =
    validatePasswords(password, confirmPassword)

  const isPasswordValid =
    hasSufficientLength && hasUpperCase && hasSymbol && hasMatchingPassword
  const [shouldDisplayPasswordInfo, setShouldDisplayPasswordInfo] =
  useState<boolean>(false)
  const onSignUp = async () => {
    try {
      await signUpWithEmail({
        username: email,
        password,
        autoSignIn: { enabled: true },
      })
      handleOtpNavigation(navigate, {
        actionType: 'SIGNUP_EMAIL',
        username: email,
        from,
        destination: email,
      })
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'UsernameExistsException':
            setErrorText(
              'A username with that email already exists. Try logging in by clicking "Existing User Signin"',
            )
            break
          case 'LimitExceededException':
            setErrorText(
              `Unfortunately, mePrism is experiencing increased traffic and is unable to handle your sign up request. Please wait 15 minutes and try again.`,
            )
            AnalyticsService.error('CognitoLimit', error)
            break
          default:
            AnalyticsService.error(`Unhandled Authorization error: `, error)
            setErrorText(
              'An error occurred processing your signup request. Please try again later.',
            )
            break
        }
      } else {
        AnalyticsService.error(`Unhandled Authorization error: `, error)
      }
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Container fixed maxWidth={'md'} sx={{ my: 20, }}>
        <Box
          sx={{
            maxWidth: MpTheme.layouts.widths.sm,
            mx: 'auto',
            my: 10,
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left',
            flexDirection: 'column',
          }}>
          <Typography variant={'h2'}>Sign Up as an Admin</Typography>
        </Box>
        <Stack
          sx={{ maxWidth: MpTheme.layouts.widths.sm, mx: 'auto', my: 10 }}
          spacing={5}>
          <TextField
            fullWidth
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            color={'primary'}
            variant={'outlined'}
            autoComplete="email"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              placeholder: 'Email',
            }}
          />
          <PasswordField
            placeholder="Password"
            value={password}
            onFocus={() => {
              setShouldDisplayPasswordInfo(true)
            }}
            onChange={(event) => setPassword(event.target.value)}
            variant={'outlined'}
            autoComplete="new-password"
            InputLabelProps={{ shrink: true }}
          />
          <PasswordField
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            onFocus={() => {
              setShouldShowMatchingPasswords(true)
            }}
            placeholder="Confirm password"
            variant={'outlined'}
            InputLabelProps={{ shrink: true }}
            autoComplete="new-password"
          />
            { shouldDisplayPasswordInfo &&(
              <Box >
                <ValidationHint
                  isValid={hasSufficientLength}
                  text={`At least ${minPasswordLength} characters`}
                />
                <ValidationHint
                  isValid={hasUpperCase}
                  text={'Contains at least one upper case letter'}
                />
                <ValidationHint
                  isValid={hasSymbol}
                  text={'Contains a symbol'}
                />
                {shouldShowMatchingPasswords && (
                  <ValidationHint
                    isValid={hasMatchingPassword}
                    text={'Passwords match'}
                  />
                )}
              </Box>
            )}
          {errorText && (
            <Box>
              <Typography color={'error'}>{errorText}</Typography>
            </Box>
          )}
          <Box>
            <Button
              disabled={!isPasswordValid}
              sx={{
                background: '#426BF0',
                width: '100%',
                fontFamily: fonts.Inter.SemiBold,
                '&.Mui-disabled': {
                  background: '#EFEDFDB2',
                  color: '#ffffff',
                },
              }}
              size={'large'}
              onClick={onSignUp}>
              Create Account
            </Button>
          </Box>
        </Stack>
        <Box
          sx={{
            maxWidth: MpTheme.layouts.widths.sm,
            mx: 'auto',
            my: 10,
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              marginBottom: 20,
            },
            [theme.breakpoints.up('md')]: {
              marginBottom: shouldDisplayPasswordInfo? 45:68,
            },
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <Typography variant={'body2'}>
            Already have an account? 
            <Link  sx={{ ml: 1 }}component={RouterLink} to="/login/signin" underline="none">
               Sign In
            </Link>
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default SignUpEmail
