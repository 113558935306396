import * as React from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Outlet,useLocation } from 'react-router-dom'
import { MpReColorPalette, theme } from '../../../config/SigninTheme'
import logo from '@meprism/shared/src/assets/icons/logos/mePrism_business.svg'
import {
    Link,
    Stack,
    Tab,
  Tabs,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material'
import MpTheme from '../../../config/MpTheme'


function SignInSideRight() {
    const location = useLocation();
    const hideTabs = location.pathname.includes('signin');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ThemeProvider theme={theme}>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          square
          sx={{
            backgroundColor: MpReColorPalette.background.default,
            p:5,
            display: !isMobile?'flex':'unset',
            alignItems: !isMobile?'left':'unset',
            justifyContent: !isMobile?'left':'unset',
          }}>
         <Box
        sx={{
          width:'100%',
          position: 'relative', // Set the container to relative position
         mb: isMobile && hideTabs? 20:'unset'
        }}
      >
        {isMobile &&(<Box
          sx={{
            maxWidth: MpTheme.layouts.widths.sm,
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left',
            
          }}>
        <img src={logo} alt="Logo" style={{ width: 200, height: 42 }} /></Box>)}
          <Box
            sx={{
              maxWidth: MpTheme.layouts.widths.sm,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              alignItems: 'left',
              my:5
            }}>
            {!hideTabs && !isMobile &&(
            <Tabs
            value={0}
            indicatorColor="secondary"
            textColor="primary"
            variant="fullWidth"  // Set the variant to "fullWidth"
          >
            <Tab
              label="1. Sign Up"
              sx={{
                flexGrow: 1,  // Use flexGrow to make the Tab take up available space
                margin: '0 5px',
                textTransform: 'none',
                color: 'inherit',
                '&.Mui-selected': { color: 'secondary.main' },
              }}
            />
            <Tab
              label="2. Verify"
              sx={{
                flexGrow: 1,
                margin: '0 5px',
              }}
            />
            <Tab
              label="3. Setup Profile"
              sx={{
                flexGrow: 1,
                margin: '0 5px',
              }}
            />
          </Tabs>)}
            {!hideTabs && isMobile && (
            <Tabs
              value={0}
              sx={{
                ".MuiTab-root":{ alignItems: "start", }, }}
              indicatorColor="secondary"
              textColor="primary"
              centered={false}>
              <Tab label="1. Sign Up" style={{width:'40%'}} sx={{ margin: '0 5px',flexGrow: 1,textTransform:'none',color: 'inherit', '&.Mui-selected': { color: 'secondary.main', } }}/>
              <Tab label="2." style={{width:'20%'}}  sx={{margin: '0 5px',flexGrow: 0,}}/>
              <Tab label="3." style={{width:'20%'}}sx={{margin: '0 5px',flexGrow: 0,}} />
            </Tabs>)}
            <Outlet/>
            { !hideTabs ? (
            <Box
          sx={{
            maxWidth: MpTheme.layouts.widths.sm,
            display: 'flex',
            alignItems: 'center',
            position: 'absolute', // Set absolute position
            bottom: isMobile?'-10px':'30px',
           width:'100%',
            justifyContent: 'center',
            flexDirection: 'column',
            
          }}>
          <Stack direction="row" spacing={10} >
            <Link
              variant="body2"
              href="https://meprism.com/terms-conditions"
              sx={{ color: '#9135E0' }}>
              Terms & Conditions
            </Link>
            <Link
              variant="body2"
              href="https://meprism.com/privacy-policy"
              sx={{ color: '#9135E0' }}>
              Privacy Policy
            </Link>
            <Link
              variant="body2"
              href="https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17"
              sx={{ color: '#9135E0' }}>
              Contact Us
            </Link>
          </Stack>
        </Box>):(<Box
          sx={{
            maxWidth: MpTheme.layouts.widths.sm,
            display: 'flex',
            alignItems: 'center',
            position: 'absolute', // Set absolute position
            bottom: isMobile?'-60px':'30px',
           width:'100%',
            justifyContent: 'center',
            flexDirection: 'column',
            
          }}>
          <Stack direction="row" spacing={10} >
            <Link
              variant="body2"
              href="https://meprism.com/terms-conditions"
              sx={{ color: '#9135E0' }}>
              Terms & Conditions
            </Link>
            <Link
              variant="body2"
              href="https://meprism.com/privacy-policy"
              sx={{ color: '#9135E0' }}>
              Privacy Policy
            </Link>
            <Link
              variant="body2"
              href="https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17"
              sx={{ color: '#9135E0' }}>
              Contact Us
            </Link>
          </Stack>
        </Box>)}
          </Box></Box>
        </Grid>
    </ThemeProvider>
  )
}

export default SignInSideRight
