import React, { PropsWithChildren, Suspense } from 'react'
import { Await, Navigate, useRouteLoaderData } from 'react-router-dom'
import { CorporateProfileSelector } from '../../redux/CorporateProfile/CorporateProfileSlice'
import { LoadingFallback } from '../shared/atom/LoadingFallback'

const CorporateProfileWrapper = ({ children }: PropsWithChildren) => {
  const corporateProfile = CorporateProfileSelector()

  if (!corporateProfile) {
    return <Navigate to={'/onboarding'} replace />
  }

  return <>{children}</>
}

export const RequireCorporateProfile = (props: PropsWithChildren) => {
  const { corporateProfile } = useRouteLoaderData('main') as any

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Await
        resolve={corporateProfile}
        errorElement={<Navigate to={'/onboarding'} replace />}>
        <CorporateProfileWrapper>{props.children}</CorporateProfileWrapper>
      </Await>
    </Suspense>
  )
}
