import {
  addListener,
  createListenerMiddleware,
  TypedAddListener,
  TypedStartListening,
} from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from './store'
import { portalAppThunkExtra, PortalAppThunkExtraType } from './thunkExtra'

export const listenerMiddleware = createListenerMiddleware({
  extra: portalAppThunkExtra,
})

export type AppStartListening = TypedStartListening<
  RootState,
  AppDispatch,
  PortalAppThunkExtraType
>

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch,
  PortalAppThunkExtraType
>
