import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { RemovedEmployeesSelector } from '../../redux/corporateService'
import MpInviteSummary from '../shared/molecule/MpInviteSummary'
import RecentlyRemovedCard from '../shared/molecule/RecentlyRemovedCard'

const Home = () => {
  const location = useLocation()
  const removedEmployees = RemovedEmployeesSelector()

  return (
    <Container fixed maxWidth={'lg'} sx={{ mb: 16, mt: '12px' }}>
      <Typography variant={'h1'} sx={{ marginBottom: '40px' }}>
        Employee Dashboard
      </Typography>
      <Grid container columnSpacing={35} rowSpacing={5}>
        <Grid item xs={12} md={6}>
          <Outlet />
        </Grid>
        <Grid item xs={12} md={6}>
          <MpInviteSummary />
          {!location.pathname.includes('removed') &&
            removedEmployees.length > 0 && <RecentlyRemovedCard />}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home
