import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { currentEnvironment } from '../utils/helpers'
import { listenerMiddleware } from './listenerMiddleware'
import { RootReducer } from './rootReducer'
import { portalAppThunkExtra, PortalAppThunkExtraType } from './thunkExtra'

function configureAppStore() {
  const appStore = configureStore({
    reducer: RootReducer,
    devTools: ['local', 'sandbox', 'development'].includes(
      currentEnvironment(),
    ),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // serializableCheck: {
        //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // },
        thunk: {
          extraArgument: portalAppThunkExtra,
        },
      }).prepend(listenerMiddleware.middleware),
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducer', () =>
      appStore.replaceReducer(RootReducer),
    )
  }

  return appStore
}
export const store = configureAppStore()
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof RootReducer>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  PortalAppThunkExtraType,
  AnyAction
>
