import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  logOutUser,
  selectMuid,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { CircularProgress, Container } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../redux/storeExports'

const Logout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isUserAuthenticated = useAppSelector(selectMuid)

  useEffect(() => {
    dispatch(logOutUser())
  }, [dispatch])

  useEffect(() => {
    if (!isUserAuthenticated) {
      navigate('/login')
    }
  }, [isUserAuthenticated, navigate])

  return (
    <Container fixed maxWidth={'sm'}>
      <CircularProgress />
    </Container>
  )
}

export default Logout
