import * as React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom'
import background from '../../../assets/images/CreateBusinessAccount.png'
import Typography from '@mui/material/Typography'
import logo from '@meprism/shared/src/assets/icons/logos/mePrism_business.svg'
import icon1 from '@meprism/shared/src/assets/icons/security-tick.svg'
import icon2 from '@meprism/shared/src/assets/icons/security-safe.svg'
import icon4 from '@meprism/shared/src/assets/icons/lucide_scan-text.svg'
import icon5 from '@meprism/shared/src/assets/icons/no.svg'
import icon6 from '@meprism/shared/src/assets/icons/fast.svg'
import icon7 from '@meprism/shared/src/assets/icons/Realtime.svg'
import icon3 from '@meprism/shared/src/assets/icons/alarm.svg'
import { useMediaQuery } from '@mui/material'
import { theme } from '../../../config/SigninTheme'
function Item(props: BoxProps) {
  const { ...other } = props
  return <Box {...other} />
}
function SignInSideLeft() {
  const location = useLocation()
  const hideTabs = location.pathname.includes('onboarding')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const handleClick = () => {
    window.location.href =
      'https://meprism1.atlassian.net/servicedesk/customer/portal/1'
  }

  return (
    <Grid
      item
      xs={false}
      sm={4}
      md={7}
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        p: 10,
      }}>
        <Box
        sx={{
          position: 'relative', // Set the container to relative position
          height: '100%', // Make sure the container takes the full height
        }}
      >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'left',
        }}>
        {!isMobile && (
          <img src={logo} alt="Logo" style={{ width: 200, height: 42,marginTop:10 }} />
        )}
        {!hideTabs ? (
          <Typography variant="h1" component="div" sx={{ marginTop: 20 }}>
            Amplify your business security with mePrism
          </Typography>
        ) : (
          <Typography variant="h1" component="div" sx={{ marginTop: 20 }}>
            Why choose mePrism?
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          columnGap: 1,
          rowGap: 1,
          alignItems: 'center',
          gridTemplateColumns: 'auto, 5fr',
          mt: 10,
        }}>
       { hideTabs ?(<><Item>
          <img
            src={icon6}
            alt="Logo"
            style={{ marginRight: 8, width: 42, height: 42 }}
          />
        </Item>
        <Item>
          {' '}
          <Typography variant="body2">
          <span style={{ color: '#ffffff' }}>Fast, continuous removal </span>
          of personal data (PII) from the web
          </Typography>
        </Item></>):(<> <Item>
          <img
            src={icon1}
            alt="Logo"
            style={{ marginRight: 8, width: 42, height: 42 }}
          />
        </Item>
        <Item>
          {' '}
          <Typography variant="body2">
            Reduce the risk of{' '}
            <span style={{ color: '#ffffff' }}>data breaches</span> and
            <span style={{ color: '#ffffff' }}> cyber-attacks </span>
            before they happen
          </Typography>
        </Item></>)} 
       
      </Box>
      <Box
        sx={{
          display: 'flex',
          columnGap: 1,
          rowGap: 1,
          alignItems: 'center',
          gridTemplateColumns: 'auto, 5fr',
        }}>
          {!hideTabs?(<>  <Item>
          <img
            src={icon2}
            alt="Logo"
            style={{ marginRight: 8, width: 42, height: 42 }}
          />
        </Item>
        <Item>
          {' '}
          <Typography variant="body2" style={{ color: '#EFEDFDB2' }}>
            Avoid
            <span style={{ color: '#fff' }}> financial losses</span> from
            employee data on the web.
          </Typography>
        </Item></>):(<><Item>
          <img
            src={icon7}
            alt="Logo"
            style={{ marginRight: 8, width: 42, height: 42 }}
          />
        </Item>
        <Item>
          {' '}
          <Typography variant="body2" style={{ color: '#EFEDFDB2' }}>
          
            <span style={{ color: '#fff' }}>Real-time monitoring </span>
            & reporting
          </Typography>
        </Item></>)}
      
      </Box>
      <Box
        sx={{
          display: 'flex',
          columnGap: 1,
          rowGap: 1,
          alignItems: 'center',
          gridTemplateColumns: 'auto, 5fr',
        }}>
        <Item>
          <img
            src={icon3}
            alt="Logo"
            style={{ marginRight: 8, width: 42, height: 42 }}
          />
        </Item>
        <Item>
          {' '}
          <Typography variant="body2" style={{ color: '#EFEDFDB2' }}>
            Stay compliant
            <span style={{ color: '#fff' }}>
              {' '}
              with data protection regulations
            </span>
          </Typography>
        </Item>
      </Box>
      <Box
        sx={{
          alignItems: 'left',
          marginTop: 4,
          marginBottom: hideTabs && !isMobile ? 80 : 20,
          p: 10,
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          border:
            '2px solid var(--text-colors-stroke, rgba(255, 255, 255, 0.15))',
          borderRadius: '20px',
          backdropFilter: 'blur(20.5px)',
        }}>
        <Typography variant="h3">
          Information <span style={{ color: '#C67EFF' }}>Security</span>
        </Typography>
        <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        marginTop: 4,
      }}
    >
      {/* Item 1 */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}
        >
          <img
            src={icon4}
            alt="Icon2"
            style={{
              verticalAlign: 'middle',
              marginRight: 8,
              width: 24,
              height: 24,
            }}
          />
          <Typography variant="body3">
            mePrism is SOC 2 compliant with industry-grade cybersecurity
            standards for handling sensitive data
          </Typography>
        </Box>
      </Grid>

      {/* Item 2 */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginTop: isMobile ? 4 : 'unset',
          }}
        >
          <img
            src={icon5}
            alt="Icon2"
            style={{
              verticalAlign: 'middle',
              marginRight: 8,
              width: 24,
              height: 24,
              marginLeft: 5,
            }}
          />
          <Typography variant="body3">
            We will never sell your information.
          </Typography>
        </Box>
      </Grid>
    </Grid>
      </Box>
      <Box
        sx={{
          alignItems: 'left',

            position: 'absolute', // Set absolute position
            bottom: '20px',
            left: '20px', 
        }}>
        <Typography variant="body2">
          Need help?{' '}
          <span
            style={{
              color: '#C67EFF',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleClick}>
            Contact us
          </span>
        </Typography>
      </Box></Box>
    </Grid>
  )
}

export default SignInSideLeft
