import { Box, Container, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthLoader } from '../gateways/AuthLoader'

const ChangePasswordRedirectScreen = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = (location?.state as any)?.from || '/'

  useEffect(() => {
    const timerId = setTimeout(() => {
      navigate(from)
    }, 2000)
    return () => {
      clearTimeout(timerId)
    }
  }, [navigate, from])

  return (
    <AuthLoader>
      <Container fixed maxWidth={'sm'}>
        <Box
          mt="10px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <Typography my={1} variant={'body1'}>
            Your password redirect was successful! You are being logged in...
          </Typography>
        </Box>
      </Container>
    </AuthLoader>
  )
}

export default ChangePasswordRedirectScreen
