import React, { PropsWithChildren, Suspense } from 'react'
import {
  Await,
  defer,
  LoaderFunction,
  Navigate,
  useLoaderData,
} from 'react-router-dom'

import { store } from '../../redux/store'
import { LoadingFallback } from '../shared/atom/LoadingFallback'
import {
  fetchInitialAuthStatus,
  selectMuid,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { useAppSelector } from '../../redux/storeExports'

export const requireAuthLoader: LoaderFunction = async () => {
  const hasFetched = await store.dispatch(fetchInitialAuthStatus()).unwrap()
  return defer({
    fetchInitialAuthStatus: hasFetched,
  })
}

const RequireAuth = (props: PropsWithChildren) => {
  const isUserAuthenticated = useAppSelector(selectMuid)

  if (!isUserAuthenticated) {
    return (
      <Navigate
        to={'/login'}
        replace
        state={{ from: window.location.pathname }}
      />
    )
  }

  return <>{props.children}</>
}

const AuthWrapper = (props: PropsWithChildren) => {
  const data = useLoaderData() as any

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Await resolve={data.fetchInitialAuthStatus}>
        <RequireAuth>{props.children}</RequireAuth>
      </Await>
    </Suspense>
  )
}

export default AuthWrapper
