import { Box, Button, ButtonBase, Paper, Typography } from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import MpSvgIcon from '../atom/MpSvgIcon'

export const csvToArray = (str: string, _delimiter = ',') => {
  const sanitized = str.replace('\r', '')
  return sanitized.split('\n').map((email) => email.replaceAll(/,/g, ''))
  //.map((obj) => obj.split(delimiter))
}

interface DragAndDropFileUploadProps {
  file: any
  setFile: (file: any) => void
}

const DragAndDropFileUpload = ({
  file,
  setFile,
}: DragAndDropFileUploadProps) => {
  const [dragActive, setDragActive] = useState(false)
  const [csvError, setCsvError] = useState('')

  const browseForFile = () => {
    setCsvError('')
    const inputFileEl: HTMLInputElement | null =
      document.querySelector('input[type=file]')
    if (inputFileEl) {
      inputFileEl.click()
    } else {
      console.log('Unable to find input[type=file]')
    }
  }
  const preventDefaults = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const askToUpload = () => {
    if (file === null || csvError) {
      //we haven't done anything
      return (
        <Box
          display="flex"
          textAlign="center"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth="710px">
          <MpSvgIcon icon={'uploadFile'} />
          {csvError && (
            <Typography
              textAlign="center"
              variant="body2"
              color={'error.main'}
              sx={{ mt: '8px', mb: '8px' }}>
              {csvError}
            </Typography>
          )}
          <Typography
            textAlign="center"
            variant="body2"
            color={'#040115'}
            mt={'20px'}>
            Drag and drop files here to upload.
          </Typography>
          <Typography
            textAlign="center"
            variant="body1"
            sx={{ mt: '20px' }}
            color={'#615F6B'}>
            Or
            {dragActive ? '' : ''}
          </Typography>
          <Button variant={'contained'} sx={{ p: 2, width: '70%', mt: '20px' }}>
            Browse
          </Button>
        </Box>
      )
    } else {
      //the file has been dropped
      return (
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between">
          <Box display="flex" flexDirection="row" alignItems="center">
            <MpSvgIcon icon={'document'} />
            <Typography textAlign="center" variant="body2" color="#767676">
              {file.name}
            </Typography>
          </Box>
          <MpSvgIcon icon={'trashCan'} />
        </Box>
      )
    }
  }

  const onDropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    setCsvError('')
    preventDefaults(event)
    if (
      event.dataTransfer?.files?.[0]?.type === 'text/csv' ||
      event.dataTransfer?.files?.[0]?.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setFile(event.dataTransfer.files[0])
    } else {
      setCsvError(
        'The file you uploaded is not a .CSV or .XLSX file. Please select a .CSV or .XLSX file and try again.',
      )
    }
  }

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    setCsvError('')
    preventDefaults(event)
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true)
    } else if (event.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const browseForFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCsvError('')
    preventDefaults(event)
    setFile(event?.target?.files?.[0])
  }

  const removeFile = (event: any) => {
    preventDefaults(event)
    setFile(null)
  }

  return (
    <ButtonBase sx={{ width: '100%', borderRadius: '20px' }}>
      <Paper
        onClick={file === null ? browseForFile : removeFile}
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          border: '2px dashed #767676',
          borderRadius: '20px',
        }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          onDrop={onDropHandler}
          onDragOver={preventDefaults}
          onDragEnter={handleDrag}
          onDragLeave={preventDefaults}
          p={'40px 40px'}>
          <input
            type="file"
            accept=".csv, .xlsx"
            hidden
            onChange={browseForFileChange}
          />
          {askToUpload()}
        </Box>
      </Paper>
    </ButtonBase>
  )
}

export default DragAndDropFileUpload
