import { CognitoUser } from '@aws-amplify/auth'
import React, { createContext, PropsWithChildren, useState } from 'react'

export type AuthContextType = {
  user: CognitoUser | null | undefined
  setUser: React.Dispatch<React.SetStateAction<CognitoUser | null | undefined>>
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
})

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<CognitoUser | null>()

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}
