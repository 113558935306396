import React, { useState } from 'react'
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  Modal,
  Typography,
  useMediaQuery,
} from '@mui/material'

import MpSvgIcon from '../atom/MpSvgIcon'
import DragAndDropFileUpload, {
  csvToArray,
} from '../molecule/DragAndDropFileUpload'
import { useAppDispatch } from '../../../redux/storeExports'
import {
  CorporateActions,
  fetchUsers,
  postUserEmails,
} from '../../../redux/corporateService'
import closeicon from '@meprism/shared/src/assets/icons/successclose.png'
import Deleteicon from '@meprism/shared/src/assets/icons/Delete.svg'
import { Toast } from '../../toast/toast'
import { Logger } from '@meprism/app-utils'
import readXlsxFile from 'read-excel-file'
import { ThemeProvider } from '@emotion/react'
import { theme, fonts } from '../../../config/Dashboardtheme'



const validateEmail = (email: string) => {
  if (!email) {
    return false
  }
  // this beautiful construction is a classic from
  // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
  return !!email.toLowerCase().match(
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  )
}
interface AddEmployeeModalProps {
  open: any
}
const AddEmplyeesModal = (props:AddEmployeeModalProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultModalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingTop: '30px',
    paddingBottom: '30px',
    maxHeight: '90vh', 
    overflowY: 'auto',
    width: isMobile?'90%':'60%'
  }
  // const open = ModalOpenSelector()
  const dispatch = useAppDispatch()

  const handleClose = () => {dispatch(CorporateActions.setModalOpen(false));setVariant('fulfill');setEmails(['','',''])}


  const [emails, setEmails] = useState(['', '', ''])
  const validEmails = emails.filter(validateEmail)
  const [variant, setVariant] = useState('fulfill')

  const [file, setFile] = useState(null as any)

  const [loading, setLoading] = useState(false)

  const updateEmail = (email: string, index: number) => {
    const emailsCopy = emails.concat()
    emailsCopy[index] = email
    setEmails(emailsCopy)
  }

  const addEmail = () => {
    const emailsCopy = emails.concat()
    emailsCopy.push('')
    setEmails(emailsCopy)
  }

  const removeEmail = (index: number) => {
    const emailsCopy = emails.concat()
    emailsCopy.splice(index, 1)
    setEmails(emailsCopy)
  }

  const onSubmit = (fileToRead: any) => {
    if (file === null) {
      //no file, manual entry, we already have the emails
      setVariant('confirm')
    } else if (file.type === 'text/csv') {
      const reader = new FileReader()

      reader.addEventListener('loadend', function () {
        // reader.result contains the contents of blob as a typed array
        // we insert content of file in DOM here
        const rawData = reader.result
        const data = csvToArray(rawData as string)
        setEmails(data)
        setLoading(false)
        setVariant('confirm')
      })
      setLoading(true)
      reader.readAsText(fileToRead)
    } else if (
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      //case: xlsx
      setLoading(true)
      readXlsxFile(fileToRead)
        .then((rows) => {
          const tempEmails = rows.map((row) => row.join().replace(/,/g, ''))
          setEmails(tempEmails as string[])
          setLoading(false)
          setVariant('confirm')
        })
        .catch(() => {
          //emails will be empty, so we get the error message
          setLoading(false)
          setVariant('confirm')
        })
    }
  }

  const handleRetry = () => {
    setVariant('fulfill')
    setEmails(['', '', ''])
    setFile(null)
  }
  const handleFinalSubmit = async () => {
    setLoading(true);
    let postUserEmailsSuccess = false;
  
    try {
      await dispatch(postUserEmails(validEmails)).unwrap();
      postUserEmailsSuccess = true;
  
      setVariant('fulfill');
      setEmails(['', '', '']);
      setFile(null);
      Toast.show({
        type: 'success',
        text1: 'Users were successfully added.',
      });
    } catch (error: any) {
      setVariant('fulfill');
      Logger.error(`Error posting user emails: ${error}`);
      Toast.show({
        type: 'error',
        text1: 'There was a problem adding users. Please contact support.',
      });
    }
  
    if (postUserEmailsSuccess) {
      await dispatch(fetchUsers()).unwrap();
    }
  
    setLoading(false);
    handleClose();
  };
  
  
  if (loading) {
    return <Typography>Loading...</Typography>
  }

  const getBody = () => {
    if (variant === 'fulfill') {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Box sx={defaultModalStyle} >
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  zIndex: 1, // Adjust the zIndex to make sure it's above other elements
                  color: '#ffffff',
                }}>
                <img src={closeicon} style={{ width: '42px' }} />
              </IconButton>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Typography
                  variant={'h2'}
                  sx={{ font: fonts.Inter.Bold, color: '#1A1B20' }}>
                  Invite Employees
                </Typography>
              </Box>
              <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
              <Box>
                <DragAndDropFileUpload
                  file={file}
                  setFile={(f: any) => setFile(f)}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}>
                <Divider sx={{ flex: 1 }} />
                <Typography
                  sx={{ pl: '5px', pr: '5px' }}
                  variant={'body3'}
                  color={'#767676'}>
                  *.csv or .xlsx (with one email address per line)
                </Typography>
                <Divider sx={{ flex: 1 }} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}>
                {emails.map((email, index) => (
                  <Box
                    sx={{
                      width: '100%',
                      marginBottom: '10px',
                      background: index % 2 === 0 ? '#C67EFF1A' : '#FFFFFF',
                      p: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    key={index}>
                    <Box sx={{ flex: 1 }}>
                      <Input
                        key={index}
                        value={emails[index]}
                        onChange={(event) =>
                          updateEmail(event.target.value, index)
                        }
                        placeholder={'employee@meprism.com'}
                        sx={{
                          '&.MuiInputBase-root': {
                            '&.MuiInput-root::before': {
                              content: 'none',
                            },
                          },
                          borderRadius:'5px',
                          border: '1px solid #A0A0A4',
                          p: 3,
                          flex: 1,
                          fontFamily: fonts.Inter[300],
                          fontSize: '16px',
                          width:isMobile?'90%':'50%'
                        }}
                      />
                    </Box>
                    {index !== 0 ? (
                      <Box>
                        <img
                          src={Deleteicon}
                          onClick={() => removeEmail(index)}
                          style={{ width: '20px' }}
                        />
                      </Box>
                    ):(<Box ml='20px'></Box>)}
                  </Box>
                ))}

                <ButtonBase
                  onClick={() => addEmail()}
                  sx={{ marginTop: '10px' }}>
                  <MpSvgIcon
                    icon={'circlePlus'}
                    style={{ marginRight: '5px' }}
                  />
                  <Typography variant={'h4'} color={'#426BF0'}>
                    Add Another
                  </Typography>
                </ButtonBase>
              </Box>

              <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
              <Button
                sx={{p:3,width:isMobile?'100%':'30%'}}
                onClick={() => onSubmit(file)}
                variant={'outlined'}
                disabled={false}>
                Save & Continue
              </Button>
            </Box>
          </ThemeProvider>
        </>
      )
    } else {
      return (
        <>
          <Box sx={defaultModalStyle}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {validEmails.length > 0 ? (
                <>
                  <Typography
                    variant={'h2'}
                    sx={{
                      font: fonts.Inter.Bold,
                      color: '#1A1B20',
                      marginRight: '5px',
                    }}>
                    Adding{'  '}
                  </Typography>
                  <Typography
                    variant={'h2'}
                    sx={{
                      font: fonts.Inter.Bold,
                      color: '#1A1B20',
                      marginRight: '5px',
                    }}>
                    {' '}
                    {validEmails.length}
                    {'  '}
                  </Typography>
                  <Typography
                    variant={'h2'}
                    sx={{
                      font: fonts.Inter.Bold,
                      color: '#1A1B20',
                    }}>
                    Employees
                  </Typography>
                </>
              ) : (
                <Typography
                  variant={'h1'}
                  sx={{
                    font: fonts.Inter.Bold,
                    color: '#1A1B20',
                    marginRight: '5px',
                  }}>
                  Did not find any valid email addresses
                </Typography>
              )}
              <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 1, // Adjust the zIndex to make sure it's above other elements
            color: '#ffffff',
          }}
        >
          <img src={closeicon} style={{width:'42px'}}/>
        </IconButton>
            </Box>
            <Divider sx={{ marginBottom: '10px', marginTop: '20px' }} />
            <List
              sx={{
                maxHeight: '300px',
                overflow: 'auto',
              }}>
              {validEmails.length > 0 ? (
                validEmails.map((email) => (
                  <ListItem sx={{ marginBottom: '10px' }} key={email}>
                    <Typography variant={'caption'}>{email}</Typography>
                  </ListItem>
                ))
              ) : (
                <>
                  <Typography variant={'h2'} sx={{ my: 4 }}>
                    Troubleshooting
                  </Typography>
                  <Typography
                    variant={'body2'}
                    sx={{ fontFamily: fonts.Inter[600], mb: '5px' }}>
                    A few things to try:
                  </Typography>
                  <ul>
                    <li>
                      <Typography variant={'body2'}>
                        Ensure all email address are in the appropriate format:
                        <i>name@company.com</i>
                      </Typography>
                    </li>
                    <li>
                      <Typography variant={'body2'}>
                        Confirm that all email addresses are in one column in
                        the spreadsheet you upload
                      </Typography>
                    </li>
                  </ul>
                </>
              )}
            </List>
            <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
            {validEmails.length > 0 ? (
              <Button onClick={handleFinalSubmit}>Invite Employees</Button>
            ) : (
              <>
                <Button variant={'contained'} onClick={handleRetry}>
                  Try Again
                </Button>
                <Button
                  sx={{marginLeft:'5px'}}
                  href={
                    'https://meprism1.atlassian.net/servicedesk/customer/portal/1'
                  }>
                  Contact Support
                </Button>
              </>
            )}
          </Box>
        </>
      )
    }
  }

  return (
    <>
      {/* <Button
        disabled={!corporateCode}
        onClick={handleOpen}
        variant={'text'}
        sx={{
          marginLeft: '64px',
          marginTop: '32px',
          marginRight: '64px',
          marginBottom: '32px',
        }}>
        <MpSvgIcon
          icon={'invite'}
          width={'20px'}
          height={'20px'}
          style={{
            marginRight: '5px',
          }}
          color={corporateCode ? '#426BF0' : '#767676'}
        />
        Invite Employees
      </Button> */}
      <Modal
        open={props.open}
        onClose={handleClose}
        sx={{
          backdropFilter: 'blur(5px)',
        }}>
        {getBody()}
      </Modal>
    </>
  )
}

export default AddEmplyeesModal
