import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { forgotPassword } from '../../services/AuthenticationService'
import { useNavigate } from 'react-router-dom'
import { AnalyticsService } from '../../services/AnalyticsService'
import { AuthLoader } from '../gateways/AuthLoader'
import { Box, Button, Container, Typography } from '@mui/material'

const PasswordReset = () => {
  const [email, setEmail] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')
  const navigate = useNavigate()

  const onSubmit = async () => {
    try {
      await forgotPassword(email)
      navigate('/changePassword', { state: { username: email } })
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'InvalidUsername':
            setErrorText(
              'That is not a valid registered email. Please try again with a different address.',
            )
            break
          default:
            setErrorText(
              'There was an error processing your request. Please try again later.',
            )
            AnalyticsService.error(`Unhandled Authorization error: `, error)
            break
        }
      } else {
        AnalyticsService.error(`Unhandled Authorization error: `, error)
      }
    }
  }

  return (
    <AuthLoader>
      <Container fixed maxWidth={'sm'}>
        <Box
          mt="10px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <Typography variant={'body1'} my={2}>
            Enter your email address to reset your password
          </Typography>
          <TextField
            helperText={'ex. john.smith@domain.com'}
            value={email}
            fullWidth
            onChange={(event) => setEmail(event.target.value)}
            color={'primary'}
            variant={'outlined'}
            sx={{
              margin: 2,
            }}
            autoComplete="email"
            label={'Email'}
          />
          <Box sx={{ minHeight: 100 }}>
            <Typography color={'error'}>{errorText}</Typography>
          </Box>
          <Button
            variant={'contained'}
            sx={{ mt: 'auto', mb: 1 }}
            disabled={email === ''}
            onClick={onSubmit}>
            Confirm Email
          </Button>
        </Box>
      </Container>
    </AuthLoader>
  )
}

export default PasswordReset
