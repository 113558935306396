import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import React, { useRef } from 'react'
import { GoogleMapsService } from '@meprism/shared/src/services/GoogleMapService'
import { ProfileAddress } from '@meprism/shared/src/redux/profile/ProfileTypes'
import { FormHelperText, Typography } from '@mui/material'
import { CSSObject } from '@emotion/react'

const defaultMapOptions = {
  zoom: 4,
  center: { lat: -34.397, lng: 150.644 },
} as const

export type CorporateAddress = ProfileAddress

interface AddressAutocompleteProps {
  defaultValue?: string
  onChange: (address: CorporateAddress | undefined) => void
  errorText?: string
}

export const AddressAutocomplete = ({
  onChange,
  defaultValue,
  errorText,
}: AddressAutocompleteProps) => {
  // const t: GooglePlacesAutocompleteProps = {
  //   selectProps: 'foo',
  // }
  const theme = useTheme()
  const hiddenMapRef = useRef<HTMLDivElement>(null)

  const getPlaceDetails = (
    placeId: string,
  ): Promise<google.maps.places.PlaceResult> => {
    // Google maps place details requires a map, which is silly but we can make one with display none
    new google.maps.Map(
      hiddenMapRef.current as HTMLDivElement,
      defaultMapOptions,
    )
    const service = new google.maps.places.PlacesService(
      hiddenMapRef.current as HTMLDivElement,
    )
    return new Promise((resolve, reject) => {
      service.getDetails({ placeId }, (details, status) => {
        if (!details || status !== 'OK') {
          reject(details)
        } else {
          resolve(details)
        }
      })
    })
  }

  const onAddressChange = async (val: any) => {
    const data = val.value
    const deets = await getPlaceDetails(val.value.place_id)
    // utc offset deprecated, uses utc_offset_minutes instead
    if (deets?.utc_offset) {
      delete deets.utc_offset
    }
    const address = GoogleMapsService.handleMapsResult(data, deets as any)
    onChange(address)
  }
  const choosetheme = location.pathname.includes('onboarding');
  return (
    <Box>
      {!choosetheme ? (<GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        // selectProps={{
        //   foo: 'bar',
        // }}
        selectProps={{
          onChange: onAddressChange,
          placeholder: (
            <Typography variant={'body1'}>
              {defaultValue || 'Company Address *'}
            </Typography>
          ),
          styles: {
            container: (style) => ({
              ...style,
              fontFamily: 'PlusJakartaSansRegular',
            }),
            valueContainer: (style) => ({
              ...style,
              padding: '8.5px 8.5px',
            }),
            input: (style) => ({
              ...style,
              fontFamily: 'PlusJakartaSansRegular',
              fontWeight: 'bold',
              color: theme.palette.primary.dark,
            }),
            singleValue: (style) => ({
              ...style,
              ...(theme.typography.body1 as CSSObject),
            }),
            menu: (style) => ({ ...style, zIndex: 9999 }),
          },
          theme: (defaultTheme) => ({
            ...defaultTheme,
            colors: {
              ...defaultTheme.colors,
              primary: theme.palette.primary.main,
              primary25: theme.palette.primary.light,
            },
          }),
        }}
      />):( <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        // selectProps={{
        //   foo: 'bar',
        // }}
        selectProps={{
          onChange: onAddressChange,
          placeholder: (
            <Typography variant={'body1'}>
              {defaultValue || 'Company Address *'}
            </Typography>
          ),
          styles: {
            container: (style) => ({
              ...style,
              fontFamily: 'InterRegular',
              background:"#1A1B20",
              color:'#ffffff',
              borderRadius:'30px'
            }),
            indicatorsContainer:(style) => ({
              ...style,
              background:"#1A1B20",
              color:'#ffffff',
              '&:hover': {
                backgroundColor: '#1A1B20',
              },
            }),
            valueContainer: (style) => ({
              ...style,
              padding: '8.5px 8.5px',
              background:"#1A1B20",
              color:'#ffffff',
              '&:hover': {
                backgroundColor: '#1A1B20',
              },
            }),
            input: (style) => ({
              ...style,
              fontFamily: 'InterRegular',
              fontWeight: 'bold',
              // color: theme.palette.primary.dark,
              backgroundColor: '#1A1B20',
              color:'#ffffff',
              '&:hover': {
                backgroundColor: '#1A1B20',
              },
            }),
            
            singleValue: (style) => ({
              ...style,
              ...(theme.typography.body1 as CSSObject),
              background:"#1A1B20",
              '&:hover': {
                backgroundColor: '#1A1B20',
              },
              color:'#ffffff'
            }),
            menu: (style) => ({ ...style, zIndex: 9999 ,background:"#1A1B20", color:'#ffffff',  '&:hover': {
              backgroundColor: '#1A1B20',
            },}),
          },
          theme: (defaultTheme) => ({
            ...defaultTheme,
            colors: {
              ...defaultTheme.colors,
              primary: theme.palette.primary.main,
              primary25: theme.palette.primary.light,
              background:"#1A1B20"
            },
          }),
        }}
      />)}
     {errorText &&(<FormHelperText variant={'outlined'} error>
        {errorText || ' '}
      </FormHelperText>)}
      
      <Box sx={{ display: 'none' }}>
        <div ref={hiddenMapRef} />
      </Box>
    </Box>
  )
}
