import { Box, Divider, Paper, Typography } from '@mui/material'
import React from 'react'
import {
  ActiveEmployeesSelector,
  InvitedEmployeesSelector,
} from '../../../redux/corporateService'
import { fonts } from '../../../theme/theme'

const MpInviteSummary = () => {
  const acceptedEmployees = ActiveEmployeesSelector()
  const invitedEmployees = InvitedEmployeesSelector()
  return (
    <Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: '64px',
          paddingTop: '40px',
          paddingRight: '64px',
          paddingBottom: '40px',
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant={'h1'}
            sx={{ fontFamily: fonts.Jakarta[300], color: '#426BF0' }}>
            {acceptedEmployees.length}
          </Typography>
          <Typography
            variant={'body1'}
            sx={{ fontFamily: fonts.Jakarta[400], color: '#767676' }}>
            Participating
          </Typography>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
          <Typography
            variant={'h1'}
            sx={{ fontFamily: fonts.Jakarta[300], color: '#767676' }}>
            {invitedEmployees.length}
          </Typography>
          <Typography
            variant={'body1'}
            sx={{ fontFamily: fonts.Jakarta[400], color: '#767676' }}>
            Pending Invitations
          </Typography>
        </Box>
      </Box>
      <Divider />
      {/* <AddEmplyeesModal /> */}
    </Paper>
  )
}

export default MpInviteSummary
