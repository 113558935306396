import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../redux/storeExports'
import { fonts } from '../../../theme/theme'
import { MpSecondaryButton } from '../atom/buttons/MpSecondaryButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import { selectAccountEmail } from '@meprism/shared/src/redux/authentication/authenticationSlice'

const MpFooter = () => {
  const accountEmail = useAppSelector(selectAccountEmail)
  const textStyle = {
    ...fonts.Jakarta.Regular,
    fontSize: '14px',
  }
  const theme = useTheme()
  const isWide = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Grid
      container
      justifyContent={'space-between'}
      rowSpacing={2}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 'auto',
        justifySelf: 'flex-end',
        maxWidth: 'xl',
        mt: 'auto',
        mx: 'auto',
        borderTop: '1px solid #EDEDED',
        py: 6,
      }}>
      <Grid item xs={12} sm={6}>
        <Stack
          direction={'row'}
          sx={{ alignItems: 'center', mx: 5 }}
          spacing={2}
          justifyContent={isWide ? 'flex-start' : 'space-between'}
          divider={
            <Typography
              variant={'caption'}
              sx={{ ...textStyle, color: '#767676', fontSize: '6px' }}>
              •
            </Typography>
          }>
          <Typography
            variant={'caption'}
            sx={{ ...textStyle, color: '#767676' }}>
            © mePrism. Inc.
          </Typography>
          <Link
            href={'https://meprism.com/terms'}
            sx={{ ...textStyle, color: 'primary' }}>
            Terms of Use
          </Link>
          <Link
            href={'https://meprism.com/privacy-policy'}
            sx={{ ...textStyle, color: 'primary' }}>
            Privacy Policy
          </Link>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack
          direction={'row'}
          spacing={{ sm: 5, xs: 0 }}
          sx={{ mx: 5 }}
          justifyContent={isWide ? 'flex-end' : 'space-between'}>
          <MpSecondaryButton
            variant={'outlined'}
            href={
              'https://meprism1.atlassian.net/servicedesk/customer/portal/1'
            }>
            Customer Support
          </MpSecondaryButton>
          <MpSecondaryButton
            variant={'outlined'}
            href={
              'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/8?email=' +
              accountEmail
            }>
            Leave Feedback
          </MpSecondaryButton>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default MpFooter
