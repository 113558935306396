import { Logger } from '@meprism/app-utils'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { useSelector } from 'react-redux'
import { Toast } from '../components/toast/toast'
import { MEPRISM_PRICING_INFO } from '../config/constants'
import { RootState } from './store'
import { useAppSelector } from './storeExports'

export type CorporateEmployee = {
  active: boolean
  muid: string | null
  email: string
  invite_status:
    | 'invited'
    | 'queued'
    | 'deleted'
    | 'accepted'
    | 'expired'
    | 'failed'
  invite_time: string
  brokers_found: number
  brokers_in_progress: number
  brokers_removed: number
}

export type CorporateUserStats = {
  total_employees_sites_in_found: number
  total_employees_sites_in_new: number
  total_employees_sites_in_removed: number
  total_employees_sites_in_removing: number
  total_employees_brokers_in_found: number
  total_employees_brokers_in_new: number
  total_employees_brokers_in_removed: number
  total_employees_brokers_in_removing: number
  total_participating_employees: number
  total_pending_employees: number
}
export interface CorporateState {
  UserStatus: CorporateUserStats
  employees: CorporateEmployee[]
  code: undefined | CorporateCode
  modalOpen: boolean
}

export type CorporateCode = {
  active: boolean
  allocated: number
  code: string
  create_time: string
  creator_arn: string
  descriptions: string
  duration: number
  duration_type: string
  end_date: string | null
  entitlements: string[]
  exporation_date: string
  limit: number
  owner_buid: string
  title: string
  update_time: string
  _id: string
  cost_per_employee_cents: number
}

export const initialCorporateState: CorporateState = {
  UserStatus: {
    total_employees_sites_in_found: 0,
    total_employees_sites_in_new: 0,
    total_employees_sites_in_removed: 0,
    total_employees_sites_in_removing: 0,
    total_employees_brokers_in_found: 0,
    total_employees_brokers_in_new: 0,
    total_employees_brokers_in_removed: 0,
    total_employees_brokers_in_removing: 0,
    total_participating_employees: 0,
    total_pending_employees: 0,
  },
  employees: [] as CorporateEmployee[],
  code: undefined,
  modalOpen: false,
}

const getUsers = async (code: string): Promise<any> => {
  return API.get('Corporate', `/codes/${code}/users`, {})
}
const getUserStats = async (code: string): Promise<any> => {
  return API.get('Corporate', `/corporate/${code}/users/stats`, {})
}
const postUsers = async (emails: string[], code: string) => {
  return API.post('Corporate', `/codes/${code}/users`, { body: emails })
}

const resendInviteApiCall = async (
  code: string,
  muid: string,
): Promise<CorporateEmployee> => {
  return API.put('Corporate', `/codes/${code}/users/${muid}/resend`, {})
}

const deleteUser = async (muid: string, code: string) => {
  return API.del('Corporate', `/codes/${code}/users/${muid}`, {})
}

const getCode = async () => {
  return API.get('Corporate', '/codes', {})
}

export const fetchUsers = createAsyncThunk(
  'fetchUsers',
  async (_, { dispatch }) => {
    try {
      const code = await dispatch(fetchCode()).unwrap()
      const users = await getUsers(code)
      return users
    } catch (error) {
      Logger.error(`Error fetching users: ${error}`)
      throw error
    }
  },
)
export const fetchUsersStats = createAsyncThunk(
  'fetchUsersStats',
  async (_, { dispatch }) => {
    try {
      const code = await dispatch(fetchCode()).unwrap()
      const usersstats = await getUserStats(code)
      return usersstats
    } catch (error) {
      Logger.error(`Error fetching users stats: ${error}`)
      throw error
    }
  },
)
export const postUserEmails = createAsyncThunk(
  'postUserEmails',
  async (payload: string[], { dispatch }) => {
    try {
      const code = await dispatch(fetchCode()).unwrap()
      return await postUsers(payload, code)
    } catch (error) {
      Logger.error(`Error posting user emails: ${error}`)
      throw error
    }
  },
)

export const removeUser = createAsyncThunk(
  'removeUser',
  async (payload: string, { dispatch }) => {
    try {
      const code = await dispatch(fetchCode()).unwrap()
      return await deleteUser(payload, code)
    } catch (error) {
      Logger.error(`Error deleting user ${payload} : ${error}`)
      Toast.show({
        type: 'error',
        text1: 'We were unable to remove that user',
      })
      throw error
    }
  },
)

export const resendInvite = createAsyncThunk(
  'resendInvite',
  async (payload: string, { dispatch }) => {
    try {
      const code = await dispatch(fetchCode()).unwrap()
      const employee = await resendInviteApiCall(code, payload)
      Toast.show({ type: 'success', text1: 'Invitation successfully re-sent' })
      return employee
    } catch (error) {
      Logger.error(`Error resending invitation ${payload}: ${error}`)
      Toast.show({
        type: 'error',
        text1: 'We could not resend the invitation',
        text2: 'Please try again later',
      })
      throw error
    }
  },
)

export const fetchCode = createAsyncThunk(
  'fetchCode',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState
    const maybeCode = state.corporateService.code?.code
    if (maybeCode) {
      return maybeCode
    } else {
      try {
        const code = await getCode()
        dispatch(CorporateActions.populateCode(code))
        return code.code
      } catch (error) {
        if (error?.response?.status === 404) {
          Logger.info('Unable to find corporate code')
        } else {
          Logger.error(`Error getting corporate code: ${error}`)
        }
        throw error
      }
    }
  },
)

const { actions, reducer } = createSlice({
  name: 'corporateService',
  initialState: initialCorporateState,
  reducers: {
    populateUserStats: (
      state,
      { payload }: { payload: CorporateUserStats },
    ) => {
      console.log('PAYLOFHD:: ')
      state.UserStatus = payload
    },
    populateEmployees: (
      state,
      { payload }: { payload: CorporateEmployee[] },
    ) => {
      state.employees = payload
    },
    populateCode: (state, { payload }: { payload: CorporateCode }) => {
      state.code = payload
    },
    setModalOpen: (state, { payload }: { payload: boolean }) => {
      state.modalOpen = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resendInvite.fulfilled, (state, action) => {
      const employeeIdx = state.employees.findIndex(
        (e) => e.muid === action.payload.muid,
      )
      if (employeeIdx >= 0) {
        state.employees = [
          ...state.employees.slice(0, employeeIdx),
          action.payload,
          ...state.employees.slice(employeeIdx + 1),
        ]
      } else {
        Logger.error(
          `Unable to find update for resent invitation: ${action.payload.muid}`,
        )
      }
    })
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.employees = payload
    })
    builder.addCase(fetchUsersStats.fulfilled, (state, { payload }) => {
      state.UserStatus = payload
    })
  },
})

export const CorporateActions = actions
export const CorporateReducer = reducer

export const EmployeesSelector = () =>
  useSelector((state: RootState) => state.corporateService.employees)
export const UserStatusSelector = () =>
  useSelector((state: RootState) => state.corporateService.UserStatus)
export const ActiveEmployeesSelector = () => {
  return useSelector((state: RootState) =>
    state.corporateService.employees.filter(
      (employee) => employee.invite_status === 'accepted',
    ),
  )
}
export const InvitedEmployeesSelector = () => {
  return useSelector((state: RootState) =>
    state.corporateService.employees.filter(
      (employee) =>
        employee.invite_status === 'invited' ||
        employee.invite_status === 'queued',
    ),
  )
}
export const RemovedEmployeesSelector = () => {
  return useSelector((state: RootState) =>
    state.corporateService.employees.filter(
      (employee) =>
        employee.invite_status === 'deleted' ||
        employee.invite_status === 'failed' ||
        employee.invite_status === 'expired',
    ),
  )
}
export const InvitedAndActiveEmployeesSelector = () => {
  return useSelector((state: RootState) =>
    state.corporateService.employees.filter(
      (employee) =>
        employee.invite_status !== 'deleted' &&
        employee.invite_status !== 'failed' &&
        employee.invite_status !== 'expired',
    ),
  )
}
export const ModalOpenSelector = () =>
  useSelector((state: RootState) => state.corporateService.modalOpen)
export const SelectCorporateCode = () =>
  useAppSelector((state) => state.corporateService.code)
export const PriceSelector = () =>
  useAppSelector((state) =>
    state.corporateService.code?.cost_per_employee_cents
      ? state.corporateService.code?.cost_per_employee_cents / 100
      : MEPRISM_PRICING_INFO.ANNUAL_EMPLOYEE_COST_DOLLARS,
  )
