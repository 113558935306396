import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import React, { ChangeEvent, useState } from 'react'
import MainHeader from '../shared/organism/MainHeader'
import {
  CorporateProfileSelector,
  postCorporateProfile,
} from '../../redux/CorporateProfile/CorporateProfileSlice'
import { useAppDispatch } from '../../redux/storeExports'

const styles = {
  textField: {
    background: 'white',
    width: '100%',
  },
  checkBox: { color: '#426BF0', paddingInlineStart: 0 },
  card: {
    height: '80vh',
    borderRadius: 5,
    marginBlock: '0.8rem',
  },
  submitCompInfo: {
    background: '#426bf0',
    color: 'white',
    '&:hover': {
      color: 'black !important',
      border: '1px solid #426bf0',
      background: 'white',
    },
    '&:disabled': {
      color: 'white !important',
      background: '#84a1ff',
    },
  },

  helpIcon: {
    fontSize: '0.6em',
    color: '#c67eff',
  },
}

const CompanyInfo = () => {
  const [checked, setChecked] = useState(false)
  const [missingRequiredField, setMissingRequiredField] = useState(false)
  const [corporateProfile, setCorporateProfile] = useState(
    CorporateProfileSelector(),
  )
  const dispatch = useAppDispatch()

  if (!corporateProfile) {
    return (
      <Typography variant="h4">
        Company Info could not be fetch. Please try again later.
      </Typography>
    )
  } else {
    const {
      numberOfEmployees,
      email,
      phone,
      website,
      address,
      address: { address_unified },
      companyName,
    } = corporateProfile
    const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (
        !missingRequiredField &&
        ['companyName', 'email', 'phone', 'numberOfEmployees'].includes(
          e.target.name,
        ) &&
        e.target.value === ''
      ) {
        setMissingRequiredField(true)
      } else if (missingRequiredField) {
        setMissingRequiredField(false)
      }
      if (e.target.name === 'address') {
        setCorporateProfile({
          ...corporateProfile,
          address: {
            ...address,
            address_unified: e.target.value,
          },
        })
      } else {
        setCorporateProfile({
          ...corporateProfile,
          [e.target.name]: e.target.value,
        })
      }
    }

    const handleSubmit = async () => {
      await dispatch(
        postCorporateProfile({
          company_name: companyName,
          email: email,
          phone: phone,
          address: address,
          url: website,
          employee_num: numberOfEmployees,
        }),
      )
    }
    return (
      <Container fixed maxWidth={'lg'} sx={{ mb: 16, mt: '12px' }}>
        <MainHeader title="Company Info" />
        <Grid container columnSpacing={5} rowSpacing={5}>
          <Grid item xs={12} md={12}>
            <Card sx={styles.card}>
              <CardContent>
                <Typography variant="h3" sx={{ mb: 5 }}>
                  Billing Information
                </Typography>
                <Grid container columnSpacing={5} rowSpacing={5}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="label">
                      Number of Employees *
                    </Typography>
                    <Tooltip title="Input the maximum number of seats you may need. We will not be be billing you on this. It helps us define the seats you may need at your company level">
                      <IconButton sx={{ paddingBlock: 0 }}>
                        <HelpOutlineIcon sx={styles.helpIcon} />
                      </IconButton>
                    </Tooltip>
                    <br />
                    <TextField
                      type="number"
                      required
                      sx={styles.textField}
                      name="numberOfEmployees"
                      value={numberOfEmployees ?? 0}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="label">Company Name *</Typography>
                    <br />
                    <TextField
                      sx={styles.textField}
                      required
                      value={companyName}
                      name="companyName"
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="label">Address</Typography>
                    <br />
                    <TextField
                      sx={styles.textField}
                      name="address"
                      value={address_unified}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="label">Email Address *</Typography>
                    <br />
                    <TextField
                      sx={styles.textField}
                      required
                      name="email"
                      value={email}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="label">Phone *</Typography>
                    <br />
                    <TextField
                      sx={styles.textField}
                      required
                      value={phone}
                      name="phone"
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="label">Website</Typography>
                    <br />
                    <TextField
                      sx={styles.textField}
                      value={website}
                      name="website"
                      onChange={handleFormChange}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mt: 5 }}>
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    style={styles.checkBox}
                  />
                  <Typography variant={'caption'}>
                    I have read and agree to the{' '}
                    <Link
                      underline={'hover'}
                      style={{ textDecoration: 'underline' }}
                      href={'https://www.meprism.com/mpbusiness-dpa'}>
                      Business Services Data Processing Agreement
                    </Link>{' '}
                    and{' '}
                    <Link
                      underline={'hover'}
                      style={{ textDecoration: 'underline' }}
                      href={'https://www.meprism.com/mpbusiness-terms'}>
                      Business Terms of Service
                    </Link>
                    .
                  </Typography>
                </Box>
              </CardContent>
            </Card>
            <Button
              sx={styles.submitCompInfo}
              disabled={!checked || missingRequiredField}
              onClick={handleSubmit}>
              Save Details
            </Button>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default CompanyInfo
