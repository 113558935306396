import React, { useState } from 'react'
import {
  useMediaQuery,
  ThemeProvider,
  Select,
  MenuItem,
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { fonts, theme } from '../../../config/Dashboardtheme'
import Pagination from '@mui/material/Pagination'
import { InvitedAndActiveEmployeesSelector } from '../../../redux/corporateService'

const DashboardGrid = () => {
  const filteredEmployees = InvitedAndActiveEmployeesSelector()
  const rows = filteredEmployees
    .map((item, index) => ({
      id: index + 1,
      col1: item.email,
      col2: item.brokers_found,
      col3: item.brokers_removed,
      col4: item.brokers_in_progress,
      col5: item.invite_status,
    }))
    .filter((value, index, self) => {
      // Filter out duplicates based on the col1 (dataBroker) property
      return self.findIndex((item) => item.col1 === value.col1) === index
    })

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedStatus, setSelectedStatus] = useState('')
  const [page, setPage] = useState(0)
  const rowsPerPage = 5

  const handleMenuItemClick = (value: React.SetStateAction<string>) => {
    setSelectedStatus(value)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1)
  }

  const columns = [
    { key: 'col1', name: 'Email ID', resizable: true, width: 150 },
    { key: 'col2', name: 'No of Records Found', resizable: true, width: 150 },
    { key: 'col3', name: 'Removed', resizable: true, width: 150 },
    { key: 'col4', name: 'Removal in Progress', resizable: true, width: 150 },
    {
      key: 'col5',
      name: 'Status',
      resizable: true,
      width: 100,
      formatter: (props: any) => (
        <span
          style={{
            color: props.row.col5 === 'accepted' ? '#27AE60' : '#EB5757',
          }}>
          {props.row.col5}
        </span>
      ),
    },
    {
      key: 'col6',
      name: '',
      resizable: true,
      width: 150,
      headerRenderer: () => (
        <Select
          value={selectedStatus}
          onChange={(e) => handleMenuItemClick(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Select status' }}>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="accepted">Accepted</MenuItem>
          <MenuItem value="">All</MenuItem>
        </Select>
      ),
    },
  ]

  const filteredRows = selectedStatus
    ? rows.filter((row) => row.col5 === selectedStatus)
    : rows
  return (
    <ThemeProvider theme={theme}>
      {!isMobile ? (
        <Box
          style={{ height: 'auto', width: '100%', paddingBottom: '16px' }}
          bgcolor="#fff"
          borderRadius={5}
          p={3}>
          <Typography variant="h3" mx={3} mt={2} mb={8}>
            Removal Stats
          </Typography>
          <TableContainer>
            <Table
              style={{
                background: '#fff',
                color: '#040115',
                borderRadius: 5,
                padding: 3,
              }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.key}
                      style={{
                        borderBottom: '1px solid #426BF0',
                        ...fonts.Inter.SemiBold,
                      }}>
                      {column.name}
                      {column.key === 'col5' && (
                        <Select
                          value={selectedStatus}
                          onChange={(e) => handleMenuItemClick(e.target.value)}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Select status' }}
                          style={{ marginLeft: '8px' }}>
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value="invited">Invited</MenuItem>
                          <MenuItem value="accepted">Accepted</MenuItem>
                        </Select>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.key}
                          style={{ borderBottom: '1px solid #615F6B' }}>
                          {column.key === 'col5' ? (
                            <span
                              style={{
                                color:
                                  row.col5 === 'accepted'
                                    ? '#27AE60'
                                    : '#EB5757',
                              }}>
                              {row.col5.charAt(0).toUpperCase() +
                                row.col5.slice(1)}
                            </span>
                          ) : (
                            row[column.key as keyof typeof row]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                padding: 2,
                borderRadius: 5,
                background: '#fff',
              }}>
              <Pagination
                variant="outlined"
                shape="rounded"
                count={Math.ceil(filteredRows.length / rowsPerPage)}
                page={page + 1}
                onChange={handleChangePage}
              />
            </Box>
          </TableContainer>
        </Box>
      ) : (
        <Box
          style={{ height: 'auto', width: '100%' }}
          bgcolor="#fff"
          borderRadius={5}
          p={3}>
          <Typography variant="h3" mx={2} mt={2}>
            Removal stats
          </Typography>
          {rows.map((row) => (
            <Grid
              key={row.id}
              container
              direction="row"
              spacing={2}
              sx={{ borderBottom: '1px solid #615F6B', padding: 2 }}>
              {columns
                .filter((col) => col.key !== 'col5' && col.key !== 'col6')
                .map((col) => (
                  <Grid item key={col.key} xs={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        wordWrap: 'break-word',
                      }}>
                      <Typography variant="h5">{col.name}</Typography>
                      <Typography variant="label">
                        {' '}
                        {row[col.key as keyof typeof row]}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          ))}
        </Box>
      )}
    </ThemeProvider>
  )
}

export default DashboardGrid
