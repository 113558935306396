import React from 'react'
import './App.css'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { Logger } from '@meprism/app-utils'
import { currentEnvironment, Environments, logLevel } from './utils/helpers'
import { RootErrorFallback } from './components/Views/ErrorViews/RootErrorFallback'
import { RootLayout, rootLayoutLoader } from './components/layouts/RootLayout'
import { SignInLayout } from './components/layouts/SignInLayout'
import SignUpEmail from './components/Authentication/SignUpWithEmail'
import LoginWithEmail from './components/Authentication/LoginWithEmail'
import PasswordReset from './components/Authentication/PasswordReset'
import ChangePassword from './components/Authentication/ChangePassword'
import Logout from './components/Authentication/Logout'
import ChangePasswordRedirect from './components/Authentication/ChangePasswordRedirectScreen'
import LoginOTP from './components/Authentication/LoginOTP'
import RequireAuth, {
  requireAuthLoader,
} from './components/Authentication/RequireAuth'
import Home from './components/Views/Home'
import { CompletePassword } from './components/Authentication/CompletePassword'
import { TotpView } from './components/Authentication/Totp'
import { MainLayout, mainLayoutLoader } from './components/layouts/MainLayout'
import EmailList from './components/shared/molecule/EmailList'
import Account, { accountLoader } from './components/Views/SettingsViews/Account'
import { RequireCorporateProfile } from './components/gateways/RequireCorporateProfile'
import { OnboardingCorporateProfile } from './components/Views/OnboardingCorporateProfile'
import SignUpSuccess from './components/Authentication/SignUpSuccess'
import SidebarNavigation from './components/layouts/SidebarNavigation'
import DashboardView from './components/Views/DashboardView'
import UserManagementView from './components/Views/UserManagementView'
import CompanyInfo from './components/Views/CompanyInfo'

Logger.initializeLogger(
  {
    apiHtmlPath: '/html',
    apiLogPath: '/logs',
    apiName: 'UILogging',
    isRunningLocally: currentEnvironment() === Environments.local,
    logLevel: logLevel('DEBUG'),
    platform: 'web',
  },
  () => {},
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      errorElement={<RootErrorFallback />}
      element={<RootLayout />}
      loader={rootLayoutLoader}>
      <Route
        element={
          <>
            {/* <AuthHeader /> */}
            <Outlet />
          </>
        }>
        <Route element={<SignInLayout />} path={'login'}>
          <Route path="create" element={<SignUpEmail />} />
          <Route path="signin" element={<LoginWithEmail />} />
          <Route index element={<Navigate to={'create'} replace />} />
        </Route>
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<SignUpEmail />} />
        <Route path="/otp" element={<LoginOTP />} />
        <Route path="/success/signup" element={<SignUpSuccess />} />
        <Route path="/totp" element={<TotpView />} />
        <Route path="/signInWithEmail" element={<LoginWithEmail />} />
        <Route path="/resetPassword" element={<PasswordReset />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/completePassword" element={<CompletePassword />} />
        <Route
          path="/changePasswordSuccess"
          element={<ChangePasswordRedirect />}
        />
      </Route>
      <Route
        loader={requireAuthLoader}
        element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }>
        <Route path="/onboarding" element={<OnboardingCorporateProfile />} />
        <Route
          id={'main'}
          loader={mainLayoutLoader}
          element={
            <RequireCorporateProfile>
              <MainLayout />
            </RequireCorporateProfile>
          }>
          <Route index element={<Navigate to={'dashboard'} replace />} />
          <Route
            path={'/home'}
            element={<Home />}
            errorElement={<RootErrorFallback />}>
            <Route path={'/home'} element={<EmailList />} />
            <Route path={'/home/removed'} element={<EmailList removed />} />
          </Route>
          <Route
            path="/dashboard"
            errorElement={<RootErrorFallback />}
            element={<SidebarNavigation />}>
            <Route index element={<DashboardView />} />
            <Route path="UserManagement" element={<UserManagementView />} />
            <Route path="Settings" element={<Home />} />
            <Route path="help-support/FAQs" element={<Home />} />
            <Route path="help-support/CustomerSupport" element={<Home />} />
          </Route>
          <Route
            path="/companyinfo"
            errorElement={<RootErrorFallback />}
            element={<SidebarNavigation />}>
            <Route index element={<CompanyInfo />} />
          </Route>
          <Route
            path={'/settings'}
            element={<SidebarNavigation />}
            errorElement={<RootErrorFallback />}>
            <Route index loader={accountLoader} element={<Account />} />
          </Route>
          <Route path={'/dashboard'} element={<SidebarNavigation />} />
        </Route>
      </Route>
    </Route>,
  ),
)

function App() {
  return <RouterProvider router={router} />
}

export default App
