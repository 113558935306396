import React, { useState } from 'react'
import { Box, Typography, IconButton, useMediaQuery, ButtonBase, Menu, MenuItem } from '@mui/material'
import notify from '@meprism/shared/src/assets/icons/Notifications.svg'
import { CorporateProfileSelector } from '../../../redux/CorporateProfile/CorporateProfileSlice'
import { theme } from '../../../config/Dashboardtheme'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../redux/storeExports'
import { logOutUser } from '@meprism/shared/src/redux/authentication/authenticationSlice'
interface titleprops {
  title: string
  count?:number
}
const MainHeader = (props: titleprops) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const corporateProfile = CorporateProfileSelector()
  const name = corporateProfile?.companyName
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const navigate = useNavigate()
const dispatch=useAppDispatch()
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleLogout = async () => {
    await dispatch(logOutUser()).unwrap()
    navigate('/login')
  }
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mt={isMobile?10:5}>
      <Typography variant="h2">{props.title}
      {props.count !== undefined && props.count>0 &&(<span style={{marginLeft:5}}>{'('+(props.count)+')'}</span>)}</Typography>
      {!isMobile && (
         <div style={{ display: 'flex', alignItems: 'center' }}>
         <IconButton
           color="inherit"
           edge="end"
           style={{ marginRight: 5 }}
           onClick={handleMenuOpen}
         >
           <img src={notify} alt="icon" width="100%" />
         </IconButton>
         <ButtonBase
           sx={{ borderRadius: '10px' }}
           onClick={handleMenuOpen}
           aria-controls={menuAnchor ? 'profile-menu' : undefined}
           aria-haspopup="true"
         >
           <Typography variant="h4">Hello {name}</Typography>
         </ButtonBase>
         <Menu
           id="profile-menu"
           anchorEl={menuAnchor}
           open={Boolean(menuAnchor)}
           onClose={handleMenuClose}
           sx={{ borderRadius: '20px' }}
         >
           {/* Add other menu items if needed */}
           <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
         </Menu>
       </div>
      )}
    </Box>
  )
}
export default MainHeader
