import mixpanel from 'mixpanel-browser'
import { AnalyticsManagerInterface } from '@meprism/shared/src/services/AnalyticsManager'

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || 'dbg', {
  debug: process.env.NODE_ENV !== 'production',
})

// @TODO: type this
type MixpanelEvent = Record<string, any>

const AnalyticsManager: AnalyticsManagerInterface = {
  // below is a replacement for legacy analytics calls that used a weird api
  trackWithProperties: async (event: any, properties: any) =>
    mixpanel?.track?.(event, properties),
  //   @DEPRECATED
  //   This call is an atrocity born because in the event that something goes wrong when a user is not logged in, we have literally no way of communicating this whatsoever. That's a colossal failing, and the awful workaround is to log an error (?!) in an Analytics system. This is terminally stupid, but the upshot is basically that
  // - This method is only used for "fatal" unexpected errors in a non-authed state
  // - You should never ever make new calls to this method if you can avoid it
  // - We should actually fix the core problem because I'm embarrassed to have my name on the git blame next to this (not that it was my idea)
  error: async (
    event: string | Error | Record<string, any>,
    properties?: unknown,
  ) => {
    let err: Error | Record<string, any>
    if (typeof event === 'string' || event instanceof String) {
      err = new Error(event as string)
    } else {
      err = event as Error | Record<string, any>
    }

    let props: Record<string, any> = {}
    if (properties instanceof Error) {
      props = {
        messageDetail: properties?.message,
        nameDetail: properties?.name,
      }
    } else if (typeof properties === 'string' || event instanceof String) {
      props = {
        messageDetail: properties,
      }
    } else if (typeof properties === 'object' && properties !== null) {
      props = properties
    }

    mixpanel?.track?.(`ERROR: ${err?.name}`, {
      message: err?.message,
      name: err?.name,
      code: err?.code,
      ...props,
    })
  },
  identify: async (id: string) => mixpanel?.identify?.(id),
  set: async (properties: any) => mixpanel.people.set(properties),
  union: async (name: string, value: any) =>
    mixpanel.people?.union(name, value),
  setOnce: async (properties: any) => mixpanel.people.set_once(properties),
  timeEvent: async (event: any) => mixpanel?.time_event?.(event),

  // below this is intended for use
  // @TODO: Provide types for this
  trackTypedEvent: async (typedEvent: MixpanelEvent) => {
    const { event, ...rest } = typedEvent
    return mixpanel?.track?.(event, rest)
  },
  reset: async () => mixpanel?.reset?.(),
  registerSuperPropertiesOnce: async (properties: Record<string, any>) =>
    mixpanel?.register_once(properties),
  registerSuperProperties: async (properties: Record<string, any>) =>
    mixpanel.register(properties),
  getSuperProperty: async (property): Promise<Record<string, any>> =>
    mixpanel.get_property(property),
}

export default AnalyticsManager
