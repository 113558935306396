import React from 'react'
import { defer, LoaderFunction, Outlet } from 'react-router-dom'
import { store } from '../../redux/store'
// import MpHeader from '../shared/organism/MpHeader'
import { fetchCode, fetchUsers, fetchUsersStats } from '../../redux/corporateService'
import { getCorporateProfile } from '../../redux/CorporateProfile/CorporateProfileSlice'

export const mainLayoutLoader: LoaderFunction = async () => {
  const users = store.dispatch(fetchUsers()).unwrap()
  const userstats=store.dispatch(fetchUsersStats()).unwrap()
  const corporateProfile = store.dispatch(getCorporateProfile()).unwrap()
  const corporateCode = store.dispatch(fetchCode()).unwrap()
  return defer({ users,userstats, corporateProfile, corporateCode })
}

export const MainLayout = () => {
  return (
    <>
      {/* <MpHeader /> */}
      <Outlet />
    </>
  )
}