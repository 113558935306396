import { buildAwsEndpoints } from '@meprism/shared/src/config/aws_config'
import { Amplify, API, Hub } from 'aws-amplify'

const endpoints = buildAwsEndpoints(process.env.REACT_APP_API_FQDN as string)

export const awsConfig = {
  oauth: {},
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_SRP_AUTH',
  },
  API: { endpoints },
}

Amplify.configure(awsConfig)

export { API as configuredApi, Hub as configuredHub }
