import {
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import closeicon from '@meprism/shared/src/assets/icons/successclose.png'
import { theme } from '../../../config/Dashboardtheme'
import { useAppDispatch } from '../../../redux/storeExports'
import { fetchUsers, removeUser } from '../../../redux/corporateService'
interface SuccessLoginModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  muid: any
}

const ConfirmationModal = (props: SuccessLoginModalProps) => {
  const dispatch = useAppDispatch()
  const onDelete = async () => {
    props.setOpen(false)
    await dispatch(removeUser(props.muid as string)).unwrap()
    dispatch(fetchUsers())
  }
  const handleClose = () => {
    props.setOpen(false)
  }
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Modal
      open={props.open}
      sx={{
        backdropFilter: 'blur(5px)',
        background: 'rgba(0, 0, 0, 0.01)',
      }}
      onClose={handleClose}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          outline: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxWidth: '600px',
          transform: 'translate(-50%, -50%)',
          width: !isMobile ? '30%' : '80%',
          background: '#fff',
          p: 10,
          borderRadius: '10px',
        }}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: -60,
            right: 10,
            zIndex: 1,
            color: '#ffffff',
          }}>
          <img src={closeicon} style={{ width: '42px' }} />
        </IconButton>
        <Stack spacing={2} flexDirection={'column'}>
        <Typography variant="h2" mb={2} textAlign="center">
          Are you sure?
        </Typography>
        <Typography variant="body2" mb={2} textAlign="center" color={'#615F6B'}>
          Do you really want to remove?
        </Typography>
        <Typography variant="body2" mb={2} textAlign="center" color={'#615F6B'}>
          This action cannot be undone.
        </Typography></Stack>
        <Grid container spacing={2} mt={5}>
          <Grid item xs={6} sm={6}>
            <Button
              variant={'contained'}
              color={'darkPurple'}
              onClick={handleClose}
              sx={{
                width: '100%',
                borderRadius: '10px',
                boxShadow: 'unset',
                border: '2px solid #C67EFF',
                background: 'transparent',
                color: '#C67EFF',
                '&.Mui-disabled': {
                  background: 'transparent',
                  color: '#C67EFF',
                },
                ':hover': {
                  background: 'transparent',
                  color: '#C67EFF',
                },
              }}>
              cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              variant={'outlined'}
              color={'darkPurple'}
              onClick={() => onDelete()}
              sx={{
                width: '100%',
                borderRadius: '10px',
                boxShadow: 'unset',
                p: 2,
              }}>
              Delete
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ConfirmationModal
