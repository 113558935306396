import React, { useState } from 'react'
import PasswordField from '../shared/atom/PasswordField'
import ValidationHint from '../shared/atom/MpValidationHint'
import { useLocation, useNavigate } from 'react-router-dom'
import { Logger } from '@meprism/app-utils'
import { AuthLoader } from '../gateways/AuthLoader'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { minPasswordLength, validatePasswords } from './config'
import { handleOtpNavigation } from './LoginOTP'
import MpTheme from '../../config/MpTheme'

export interface ChangePasswordRouteParam {
  username: string
}

const ChangePassword = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { username } = state as ChangePasswordRouteParam
  const email = username
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const [shouldDisplayPasswordInfo, setShouldDisplayPasswordInfo] =
    useState<boolean>(false)
  const [shouldShowMatchingPasswords, setShouldShowMatchingPasswords] =
    useState<boolean>(false)
  const { hasMatchingPassword, hasSymbol, hasSufficientLength, hasUpperCase } =
    validatePasswords(password, confirmPassword)
  const isPasswordValid =
    hasMatchingPassword && hasSymbol && hasSufficientLength && hasUpperCase

  const onPasswordReset = async () => {
    try {
      handleOtpNavigation(navigate, {
        actionType: 'CHANGE_PASSWORD',
        username: email,
        password,
        destination: email,
      })
    } catch (error) {
      Logger.error(`Error in navigation from changePassword to otp ${error}`)
    }
  }

  return (
    <AuthLoader>
      <Container fixed maxWidth={'sm'}>
        <Box
          mt="10px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <Typography my={1} variant={'body1'}>
            Enter a new password for {email}
          </Typography>
          <Stack
            maxWidth={MpTheme.layouts.widths.sm}
            spacing={4}
            sx={{ my: 4 }}>
            <PasswordField
              fullWidth
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onFocus={() => {
                setShouldDisplayPasswordInfo(true)
              }}
              InputLabelProps={{ shrink: true }}
              label={'Password'}
              variant={'outlined'}
              autoComplete="new-password"
            />
            <PasswordField
              fullWidth
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              onFocus={() => {
                setShouldShowMatchingPasswords(true)
              }}
              InputLabelProps={{ shrink: true }}
              label={'Confirm Password'}
              variant={'outlined'}
              autoComplete="new-password"
            />
            <Box sx={{ minHeight: 100 }}>
              {shouldDisplayPasswordInfo && (
                <Box>
                  <ValidationHint
                    isValid={hasSufficientLength}
                    text={`At least ${minPasswordLength} characters`}
                  />
                  <ValidationHint
                    isValid={hasUpperCase}
                    text={'Contains at least one upper case letter'}
                  />
                  <ValidationHint
                    isValid={hasSymbol}
                    text={'Contains number or symbol'}
                  />
                  {shouldShowMatchingPasswords && (
                    <ValidationHint
                      isValid={hasMatchingPassword}
                      text={'Passwords match'}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Stack>
          <Box
            sx={{
              justifySelf: 'flex-end',
              mt: 1,
              mb: 2,
            }}>
            <Button
              variant={'contained'}
              disabled={!isPasswordValid}
              sx={{
                mt: 'auto',
                minWidth: '180px',
              }}
              onClick={onPasswordReset}>
              Reset Password
            </Button>
          </Box>
          <Button
            variant={'outlined'}
            onClick={() => navigate(-1)}
            sx={{ mt: 1, mb: 2, minWidth: '180px' }}>
            Change Email
          </Button>
        </Box>
      </Container>
    </AuthLoader>
  )
}

export default ChangePassword
