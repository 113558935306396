import { Logger } from '@meprism/app-utils'
import type {
  GooglePlaceDetail,
  PlaceType,
  GooglePlaceData,
} from 'react-native-google-places-autocomplete'
import { ProfileAddress } from '../redux/profile/ProfileTypes'

type OutputType = 'long_name' | 'short_name'
// Iterates through a list of Google Tags in order, returning the first specified matching value
const findByTag = (
  detail: GooglePlaceDetail,
  options: {
    tag: PlaceType
    type: OutputType
  },
) => {
  const found = detail.address_components.find((comp) =>
    comp.types.includes(options.tag),
  )
  if (found) {
    return found[options.type]
  }
  return undefined
}

const asProfileAddress = (
  data: GooglePlaceData,
  details: GooglePlaceDetail,
): ProfileAddress | undefined => {
  let city = findByTag(details, { tag: 'locality', type: 'long_name' })
  if (!city) {
    city = findByTag(details, { tag: 'sublocality', type: 'long_name' })
  }
  if (!city) {
    Logger.error(
      `Unable to find a locality or city  for ${JSON.stringify(
        data,
      )} ${JSON.stringify(details)}`,
    )
  }
  const state = findByTag(details, {
    tag: 'administrative_area_level_1',
    type: 'short_name',
  })
  const country = findByTag(details, { tag: 'country', type: 'short_name' })
  const zip = findByTag(details, { tag: 'postal_code', type: 'short_name' })

  if (!city || !state || !country) {
    return undefined
  }

  return {
    street1: data.structured_formatting.main_text || '',
    city,
    state,
    country,
    zip,
    address_unified: data.description,
  }
}

const asAddressString = (
  profileAddress: ProfileAddress | undefined,
): string => {
  if (profileAddress?.address_unified) {
    return profileAddress.address_unified
  }
  return [
    profileAddress?.street1,
    profileAddress?.city,
    profileAddress?.state,
    profileAddress?.country,
  ]
    .filter((e) => e)
    .join(', ')
}

const handleMapsResult = (
  data: GooglePlaceData,
  detail: GooglePlaceDetail,
): ProfileAddress | undefined => {
  if (!detail) {
    // This should NEVER happen with our configuration
    Logger.error(
      `ADDRESS ERROR: No address details available for ${JSON.stringify(
        data,
      )} `,
    )
    return undefined
  }
  const addr = asProfileAddress(data, detail)
  if (!addr) {
    // This is a PROBLEM. It can happen naturally if user enters ex. USA which lacks a city
    // but in general we really hate to see this
    Logger.error(
      `ADDRESS ERROR: Unable to parse data=${JSON.stringify(
        data,
      )} detail=${JSON.stringify(detail)}`,
    )
  }
  return addr
}

export const GoogleMapsService = {
  findByTag,
  asAddressString,
  asProfileAddress,
  handleMapsResult,
}
