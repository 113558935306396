import React, { useMemo, useRef } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import background from '../../assets/images/Businessbg.jpeg'
import backgroundmob from '../../assets/images/Bgmob.jpg'
import category from '@meprism/shared/src/assets/icons/Category.png'
import relatives from '@meprism/shared/src/assets/icons/Relatives.svg'
import org from '@meprism/shared/src/assets/icons/org.svg'
import orgactive from '@meprism/shared/src/assets/icons/orgactive.svg'
import device from '@meprism/shared/src/assets/icons/device-message.svg'
import deviceactive from '@meprism/shared/src/assets/icons/device-messageactive.svg'
import settings from '@meprism/shared/src/assets/icons/Setting.svg'
import settingactive from '@meprism/shared/src/assets/icons/Settingactive.svg'
import usersactive from '@meprism/shared/src/assets/icons/usersactive.svg'
import categoryactive from '@meprism/shared/src/assets/icons/Categoryactive.svg'
import logo from '@meprism/shared/src/assets/icons/mePrismBusinessLogo.svg'
import notify from '@meprism/shared/src/assets/icons/Notifications.svg'
import MenuIcon from '@meprism/shared/src/assets/icons/hamburger.svg'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import {
  Collapse,
  Container,
  IconButton,
  Link,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material'
import { fonts, theme } from '../../config/Dashboardtheme'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { SelectCorporateCode } from '../../redux/corporateService'
import { useAppDispatch } from '../../redux/storeExports'
import { logOutUser } from '@meprism/shared/src/redux/authentication/authenticationSlice'

const styles = {
  linkStyle: {
    margin: '0',
    fontFamily: 'InterMedium',
    fontSize: '0.875rem !important',
    lineHeight: '1.5',
    color: '#1a1b20',
    fontWeight: '400',
  },
}

const sidebarnewItems = [
  {
    id: 'dashboard',
    icon: <img src={category} alt="relatives" />,
    iconactive: <img src={categoryactive} alt="relatives" />,
    text: 'Dashboard',
    url: '/dashboard',
  },
  {
    id: 'UserManagement',
    icon: <img src={relatives} alt="relatives" />,
    iconactive: <img src={usersactive} alt="relatives" />,
    text: 'User Management',
    url: '/dashboard/UserManagement',
  },
  {
    id: 'CompanyInfo',
    icon: <img src={org} alt="relatives" />,
    iconactive: <img src={orgactive} alt="relatives" />,
    text: 'Company Info',
    url: '/companyinfo',
  },
  {
    id: 'Settings',
    icon: <img src={settings} alt="relatives" />,
    iconactive: <img src={settingactive} alt="relatives" />,
    text: 'Settings',
    url: '/settings',
  },
  {
    id: 'help',
    icon: <img src={device} alt="relatives" />,
    iconactive: <img src={deviceactive} alt="relatives" />,
    text: 'Help & Support',
    url: '/dashboard/help-support',
    submenu: [
      { id: 'FAQs', text: 'FAQs', url: 'https://meprism.com/faqs' },
      {
        id: 'CustomerSupport',
        text: 'Customer Support',
        url: 'https://meprism1.atlassian.net/servicedesk/customer/portal/1',
      },
    ],
  },
]

export default function SidebarNavigation() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const sidebarMobItems = [
    {
      id: 'dashboard',
      icon: <img src={category} alt="relatives" />,
      iconactive: <img src={categoryactive} alt="relatives" />,
      text: 'Dashboard',
      url: '/dashboard',
    },
    {
      id: 'UserManagement',
      icon: <img src={relatives} alt="relatives" />,
      iconactive: <img src={usersactive} alt="relatives" />,
      text: 'User Management',
      url: '/dashboard/UserManagement',
    },
    {
      id: 'Settings',
      icon: <img src={settings} alt="relatives" />,
      iconactive: <img src={settingactive} alt="relatives" />,
      text: 'Settings',
      url: '/settings',
    },
  ]

  const [open, setOpen] = React.useState(!isMobile)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation() || { pathname: '' }

  const [isDropdownOpen, setDropdownOpen] = React.useState(false)

  const toggleDropdown = () => {
    setOpen(false);
    setDropdownOpen(!isDropdownOpen)
  }

  const handleLogout = async () => {
    await dispatch(logOutUser()).unwrap()
    navigate('/login')
  }

  const selectedItem = useMemo(() => {
    const lastPartOfUrl = location.pathname.split('/').pop()?.toLowerCase()

    const currentSidebarItem = sidebarnewItems.find((item) =>
      item.submenu
        ? item.submenu.some(
            (subItem) =>
              subItem.url.toLowerCase() === location.pathname.toLowerCase(),
          )
        : item.id.toLowerCase() === lastPartOfUrl,
    )

    return currentSidebarItem ? currentSidebarItem.id : null
  }, [location.pathname])

  const handleItemClick = (itemId: string, url: string) => {
    if(itemId === 'FAQs' || itemId === 'CustomerSupport'){
      window.open(url, '_blank');
    }
    else{
   
    navigate(url)

  }}

  const handleDrawerToggle = () => {
    if (rootRef.current) {
      setOpen((prevOpen) => !prevOpen);
    }
  }
  const rootRef = useRef(null);
  const value = 0
  const corporateCode = SelectCorporateCode()
  const checkMenuRender = (itemId: string) => {
    if (isMobile) {
      return ['UserManagement'].includes(itemId)
        ? corporateCode
          ? true
          : false
        : true
    } else {
      return ['UserManagement', 'CompanyInfo'].includes(itemId)
        ? corporateCode
          ? true
          : false
        : true
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}  ref={rootRef} >
        <CssBaseline />
        <AppBar
          sx={{
            position: 'absolute',
            background: isMobile ? 'white' : 'transparent',
            boxShadow: 'none',
            p: isMobile ? 0 : 4,
          }}>
          <Box
            sx={{
              pr: '24px',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            {isMobile && (
              <Box>
                <IconButton
                  edge="start"
                  color="inherit"
                  sx={{ ml: 1 }}
                  aria-label="open drawer"
                  onClick={() => setOpen((prevOpen) => !prevOpen)}>
                  <img src={MenuIcon} />
                </IconButton>
              </Box>
            )}

            {isMobile && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ margin: '5px' }}>
                    <img src={logo} alt="Logo" width="100%" />
                  </Box>
                </Box>
                <Box>
                  <IconButton color="inherit" edge="end">
                    <img src={notify} alt="icon" width="100%" />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </AppBar>
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          onClick={handleDrawerToggle}
          open={open}>
          <Box
            sx={{
              margin: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}>
            {' '}
            {/* Adjust the margin value as needed */}
            <img src={logo} alt="Logo" width="100%" />
          </Box>
          <Divider />
          {sidebarnewItems.map((item, index) => (
            <>
              {checkMenuRender(item.id) && (
                <div key={item.id}>
                  {index === 4 ? (
                    <div>
                      <ListItem
                        disablePadding
                        onClick={toggleDropdown}
                        sx={{
                          background:
                            selectedItem === item.id ? '#426BF0' : 'inherit',
                          color:
                            selectedItem === item.id ? '#426BF0' : 'inherit',
                          fontFamily: fonts.Inter.Medium,
                        }}>
                        <ListItemButton
                          sx={{
                            color:
                              selectedItem === item.id ? '#426BF0' : 'inherit',
                            fontFamily: fonts.Inter.Medium,
                          }}>
                          {selectedItem == item.id ? (
                            <Box
                              // p={2}
                              bgcolor={'#426BF0'}
                              borderRadius={2}
                              alignItems="center"
                              mr={1}>
                              <ListItemIcon sx={{ minWidth: 'unset' }}>
                                {item.iconactive}
                              </ListItemIcon>
                            </Box>
                          ) : (
                            <ListItemIcon>{item.icon}</ListItemIcon>
                          )}
                          {selectedItem == item.id ? (
                            <Typography
                              variant="body1"
                              sx={{ color: '#426BF0' }}>
                              {item.text}
                            </Typography>
                          ) : (
                            <Typography variant="body1">{item.text}</Typography>
                          )}
                          {isDropdownOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse
                        in={isDropdownOpen}
                        timeout="auto"
                        unmountOnExit>
                        <List component="div" disablePadding>
                          {item.submenu?.map((subItem) => (
                            <ListItem
                              key={subItem.id}
                              disablePadding
                              onClick={() => {
                                handleItemClick(subItem.id, subItem.url)
                              }}
                              sx={{
                                paddingLeft: 4,
                                background: theme.palette.grey[100],
                              }}>
                              <ListItemButton
                                sx={{
                                  background:
                                    selectedItem === item.id
                                      ? 'var(--Text-Colors-Light-Grey, rgba(239, 237, 253, 0.70))'
                                      : 'inherit',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  color:
                                    selectedItem === subItem.id
                                      ? '#426BF0'
                                      : 'inherit', // Change 'red' to your desired selected color
                                }}>
                                {selectedItem == subItem.id ? (
                                  <Typography
                                    variant="body1"
                                    sx={{ color: '#426BF0' }}>
                                    {subItem.text}
                                  </Typography>
                                ) : (
                                  <Typography variant="body1" color={'#615F6B'}>
                                    {subItem.text}
                                  </Typography>
                                )}
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ) : (
                    <ListItem
                      key={item.id}
                      disablePadding
                      onClick={() => handleItemClick(item.id, item.url)}
                      sx={{
                        color: selectedItem === item.id ? '#426BF0' : 'inherit',
                        borderRight:
                          selectedItem === item.id
                            ? '2px solid #426BF0'
                            : 'none', // Change 'red' to your desired selected color
                      }}>
                      <ListItemButton
                        sx={{
                          background:
                            selectedItem === item.id
                              ? 'var(--Text-Colors-Light-Grey, rgba(239, 237, 253, 0.70))'
                              : 'inherit',
                          color:
                            selectedItem === item.id ? '#426BF0' : 'inherit', // Change 'red' to your desired selected color
                        }}>
                        {selectedItem == item.id ? (
                          <Box
                            // p={2}
                            bgcolor={'#426BF0'}
                            borderRadius={2}
                            display="flex"
                            alignItems="center"
                            p={1} // Adjust padding a
                            mr={3}>
                            <ListItemIcon sx={{ minWidth: 'unset' }}>
                              {item.iconactive}
                            </ListItemIcon>
                          </Box>
                        ) : (
                          <ListItemIcon>{item.icon}</ListItemIcon>
                        )}
                        {selectedItem == item.id ? (
                          <Typography variant="body1" sx={{ color: '#426BF0' }}>
                            {item.text}
                          </Typography>
                        ) : (
                          <Typography variant="body1">{item.text}</Typography>
                        )}
                      </ListItemButton>
                    </ListItem>
                  )}
                </div>
              )}
            </>
          ))}
          {isMobile && (
            <Box sx={{ position: 'absolute', bottom: 10, left: 10 }}>
              <ListItem key="termscondition">
                <Link
                  href="https://meprism.com/terms-conditions"
                  sx={styles.linkStyle}>
                  Terms & Conditions
                </Link>
              </ListItem>
              <ListItem key="privacypolicy">
                <Link
                  href="https://meprism.com/privacy-policy"
                  sx={styles.linkStyle}>
                  Privacy Policy
                </Link>
              </ListItem>
              <ListItem key="signout" onClick={handleLogout}>
                <Typography variant="body1">Sign Out</Typography>
              </ListItem>
            </Box>
          )}
        </Drawer>
        <Box
          marginLeft={isMobile ? 'unset' : 62}
          marginBottom={isMobile ? 10 : 'unset'}
          component="main"
          sx={{
            backgroundColor: theme.palette.grey[100],
            zIndex: 1,
            backgroundImage: isMobile
              ? `url(${backgroundmob})`
              : `url(${background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100vh',
            overflow: 'auto',
          }}>
          {isMobile && <Toolbar />}
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
          </Container>
          {isMobile && (
            <Box
              sx={{
                width: '100%',
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto',
              }}>
              <BottomNavigation showLabels value={value}>
                {sidebarMobItems.map(
                  (item) =>
                    checkMenuRender(item.id) && (
                      <BottomNavigationAction
                        sx={{
                          '& .MuiBottomNavigationAction-label': {
                            fontFamily: fonts.Inter.SemiBold,
                            fontSize: '11px',
                            '&.Mui-selected': {
                              '@media (max-width: 600px)': {},
                              color: '#040115',
                              fontSize: '11px',
                            },
                            color:
                              selectedItem === item.id ? '#426BF0' : '#040115',
                          },
                        }}
                        key={item.id}
                        label={item.text}
                        onClick={() => handleItemClick(item.id, item.url)}
                        icon={
                          <Box
                            sx={{
                              mb: 1,
                              borderRadius: '10px',
                              backgroundColor:
                                selectedItem === item.id
                                  ? '#426BF0'
                                  : 'transparent',
                              padding: '3px',
                            }}>
                            {selectedItem === item.id
                              ? (item.iconactive as React.ReactElement)
                              : (item.icon as React.ReactElement)}
                          </Box>
                        }
                      />
                    ),
                )}
              </BottomNavigation>
            </Box>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )
}
