import React from 'react'
import {
  Box,
  Divider,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import Stack from '@mui/material/Stack/Stack'
import PhoneIcon from '@mui/icons-material/PhoneIphone'
import MpSvgIcon from '../shared/atom/MpSvgIcon'

import { useAppDispatch, useAppSelector } from '../../redux/storeExports'
import {
  AuthenticationActions,
  selectPreferredMfa,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { MfaType } from '@meprism/shared/src/redux/authentication/authenticationTypes'
import { FeatureFlags } from '../../config/featureFlags'
import MpTheme from '../../config/MpTheme'
import { PhoneNumberMaskedInput } from '../shared/atom/inputs/PhoneNumberInput'

type MfaCardProps = {
  phone: string
  setPhone: (phone: string) => void
}

export const MfaCard = ({ phone, setPhone }: MfaCardProps) => {
  const dispatch = useAppDispatch()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(AuthenticationActions.setDraftMfa(event.target.value as MfaType))
  }
  const { draftPreferredMfa } = useAppSelector(selectPreferredMfa)

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value)
  }

  return (
    <RadioGroup value={draftPreferredMfa} onChange={handleChange}>
      <Stack
        sx={{
          bgcolor: 'blue.50',
          borderRadius: '20px',
          border: '1px solid #426BF0',
        }}
        divider={
          <Divider
            sx={{ bgcolor: 'primary.main', borderColor: 'primary.main' }}
          />
        }>
        {FeatureFlags.SMS_MFA_ENABLED && (
          <Box sx={{ display: 'flex', flexDirection: 'row', padding: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 6 }}>
                <Radio id={'SMS_MFA'} value={'SMS_MFA'} />
                <PhoneIcon color={'primary'} sx={{ mr: 4 }} />
              </Box>
            </Box>
            <Stack spacing={2}>
              <FormLabel htmlFor={'SMS_MFA'}>
                <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
                  Smartphone
                </Typography>
              </FormLabel>
              <Typography variant={'body2'} sx={{ fontSize: 14 }}>
                Receive a text message with an authentication code
              </Typography>
              {draftPreferredMfa === 'SMS_MFA' && (
                <TextField
                  fullWidth
                  onChange={handlePhoneChange}
                  value={phone}
                  InputProps={{
                    inputComponent: PhoneNumberMaskedInput,
                    placeholder: '',
                  }}
                  label={'Phone Number'}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </Stack>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', padding: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 6 }}>
              <Radio id={'SOFTWARE_TOKEN_MFA'} value={'SOFTWARE_TOKEN_MFA'} />
              <MpSvgIcon
                icon={'authenticatorTwoTone'}
                color={MpTheme.colors.primary.default}
                style={{ marginRight: '16px' }}
              />
            </Box>
          </Box>
          <Stack spacing={2}>
            <FormLabel htmlFor={'SOFTWARE_TOKEN_MFA'}>
              <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
                Authenticator App
              </Typography>
            </FormLabel>
            <Typography variant={'body2'} sx={{ fontSize: 14 }}>
              Use your mobile authenticator app to generate one-time passcodes
              code
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </RadioGroup>
  )
}
