import { responsiveFontSizes, ThemeOptions } from '@mui/material/styles'
import { createTheme } from '@mui/material'

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
    body4: true
    body5: true
    title: true
    subheader: true
    label: true
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties
    body4: React.CSSProperties
    body5: React.CSSProperties
    title: React.CSSProperties
    subheader: React.CSSProperties
    label: React.CSSProperties
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties
    body4?: React.CSSProperties
    body5?: React.CSSProperties
    title?: React.CSSProperties
    subheader?: React.CSSProperties
    label?: React.CSSProperties
  }
  interface Palette {
    purple: Palette['grey']
    pink: Palette['grey']
    cyan: Palette['grey']
    green: Palette['grey']
    white: Palette['primary']
    darkPurple: Palette['primary']
  }
  interface PaletteOptions {
    purple: PaletteOptions['grey']
    pink: PaletteOptions['grey']
    cyan: PaletteOptions['grey']
    green: PaletteOptions['grey']
    darkPurple: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true
    darkPurple: true
  }
}

export const MpColorPalette = {
  primary: {
    main: '#426BF0',
    dark: '#1B2D65',
  },
  secondary: {
    main: '#426BF0',
  },
  purple: {
    50: '#F4EBFC',
    100: '#E9D7F9',
    200: '#D3AEF3',
    300: '#BD86EC',
    400: '#A75DE6',
    500: '#9135E0',
    600: '#742AB3',
    700: '#572086',
    800: '#3A155A',
    900: '#1D0B2D',
  },
  blue: {
    50: '#E6F5FE',
    100: '#CCEAFD',
    200: '#99D5FB',
    300: '#66C1F9',
    400: '#33ACF7',
    500: '#2197F5',
    600: '#1A79C4',
    700: '#145B93',
    800: '#0C3C62',
    900: '#031E31',
  },
  grey: {
    50: '#F9F9F9',
    100: '#E3E3E3',
    200: '#C8C8C8',
    300: '#ACACAC',
    400: '#919191',
    500: '#757575',
    600: '#5E5E5E',
    700: '#464646',
    800: '#2F2F2F',
    900: '#171717',
  },
  pink: {
    50: '#FAE8F8',
    100: '#F5D2F1',
    200: '#EBA5E3',
    300: '#E177D5',
    400: '#D74AC7',
    500: '#CD1D89',
    600: '#A41794',
    700: '#7B116F',
    800: '#520C4A',
    900: '#290625',
  },
  cyan: {
    50: '#E6F5FE',
    100: '#CCEAFD',
    200: '#99D5FB',
    300: '#66C1F9',
    400: '#33A6F7',
    500: '#2197F5',
    600: '#1A79C4',
    700: '#145B93',
    800: '#0C3C62',
    900: '#031E31',
  },
  green: {
    50: '#e9faf5',
    100: '#D2F5EA',
    200: '#A5EBD6',
    300: '#78E0C1',
    400: '#4BD6AD',
    500: '#1ECC98',
    600: '#18A37A',
    700: '#127A5B',
    800: '#0C523D',
    900: '#06291E',
  },
  white: {
    main: '#ffffff',
  },
  darkPurple: {
    main: '#3A155A',
    light: '#9135E0',
    dark: '#12005e',
    contrastText: '#ffffff',
  },
  background: {
    default: '#ffffff',
  },
  text: {
    primary: '#3A155A',
    secondary: '#757575',
  },
  divider: '#EDEDED',
  error: {
    main: '#FF3D00',
    light: '#FFF3F0',
    dark: '#541400',
  },
  warning: {
    main: '#E66000',
    light: '#FFF3EB',
    dark: '#5C2600',
  },
  success: {
    main: '#3DA010',
    light: '#ECFFF1',
    dark: '#005418',
  },
  info: {
    main: '#426BF0',
    light: '#F1F4FE',
    dark: '#284090',
  },
} as const

const baseSize = 16
export const px2rem = (size = 16) => {
  return `${size / baseSize}rem`
}

export const Jakarta = {
  fontFamily: 'PlusJakartaSans',
}

export const fonts = {
  Jakarta: {
    ExtraLight: {
      //200
      fontFamily: 'PlusJakartaSansExtraLight',
    },
    200: {
      fontFamily: 'PlusJakartaSansExtraLight',
    },
    Light: {
      //300
      fontFamily: 'PlusJakartaSansLight',
    },
    300: {
      //300
      fontFamily: 'PlusJakartaSansLight',
    },
    Regular: {
      //400
      fontFamily: 'PlusJakartaSansRegular',
    },
    400: {
      //400
      fontFamily: 'PlusJakartaSansRegular',
    },
    Medium: {
      //500
      fontFamily: 'PlusJakartaSansMedium',
    },
    500: {
      //500
      fontFamily: 'PlusJakartaSansMedium',
    },
    SemiBold: {
      //600
      fontFamily: 'PlusJakartaSansSemiBold',
    },
    600: {
      //600
      fontFamily: 'PlusJakartaSansSemiBold',
    },
    Bold: {
      //700
      fontFamily: 'PlusJakartaSansBold',
    },
    700: {
      //700
      fontFamily: 'PlusJakartaSansBold',
    },
    ExtraBold: {
      //800
      fontFamily: 'PlusJakartaSansExtraBold',
    },
    800: {
      //800
      fontFamily: 'PlusJakartaSansExtraBold',
    },
  },
}

export const MpThemeOptions: ThemeOptions = {
  palette: MpColorPalette,
  spacing: 4,
  breakpoints: {
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1440 },
  },
  // Note about fonts - in order for responsive font sizes to work, lineHeight must always be a unitless ratio
  // not specified in px or rem. Because our designs are in px, I left the ratio math intact in case we
  // need to update them ex h1 below has lineHeight of 36/32 because it's a lineHeight of 36px against
  // a base font size of 32 (those apply to lg breakpoints, it'll be smaller if less)
  typography: {
    fontSize: baseSize,
    ...Jakarta,
    h1: {
      ...fonts.Jakarta.Bold,
      fontSize: px2rem(32),
      lineHeight: 36 / 32,
      color: '#3A155A',
    },
    h2: {
      ...fonts.Jakarta.Bold,
      fontSize: px2rem(24),
      lineHeight: 29 / 24,
      color: '#3A155A',
    },
    h3: {
      ...fonts.Jakarta.ExtraBold,
      fontSize: 20,
      lineHeight: 22 / 20,
    },
    h4: {
      ...fonts.Jakarta.ExtraBold,
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: 16 / 14,
    },
    h5: {
      ...fonts.Jakarta.ExtraBold,
      fontSize: 12,
      lineHeight: 14 / 12,
      letterSpacing: 1.4,
    },
    body1: {
      ...fonts.Jakarta.Regular,
      fontSize: px2rem(20),
      letterSpacing: '0.02rem',
      lineHeight: 1.5,
      color: '#3A155A',
    },
    body2: {
      ...fonts.Jakarta.Regular,
      fontSize: px2rem(16),
      letterSpacing: '0.02rem',
      lineHeight: 1.5,
      color: '#3A155A',
    },
    body3: {
      ...fonts.Jakarta.Regular,
      fontSize: px2rem(12),
      letterSpacing: '0.02rem',
      lineHeight: 1.5,
    },
    body4: {
      ...fonts.Jakarta.Regular,
      fontSize: px2rem(10),
      letterSpacing: '0.02rem',
      lineHeight: 1.5,
    },
    body5: {
      ...fonts.Jakarta.Regular,
      fontSize: px2rem(8),
      letterSpacing: '0.02rem',
      lineHeight: 1.5,
    },
    caption: {
      ...fonts.Jakarta.Regular,
      fontSize: px2rem(14),
      color: '#767676',
      lineHeight: 17 / 14,
    },
    button: {
      ...fonts.Jakarta.Bold,
      fontSize: 16,
    },
    title: {
      ...fonts.Jakarta.Light,
      fontSize: 48,
      color: '#3A155A',
      fontWeight: 700,
    },
    subheader: {
      ...fonts.Jakarta.Bold,
      fontSize: px2rem(16),
      color: '#3A155A',
    },
    label: {
      ...fonts.Jakarta.SemiBold,
      fontSize: px2rem(15),
      color: '#3A155A',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          // fontWeight: 700,
          // ':disabled': {
          //   backgroundColor: '#3A155A',
          //   opacity: 0.5,
          //   color: '#ffffff',
          // },
        },
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          ...fonts.Jakarta.Bold,
        },
        textSizeMedium: {
          // This override ensures variant=text has matching styles to variant=contained
          padding: '6px 16px',
        },
        outlined: {
          borderWidth: 2,
          // borderColor: 'white',
          // backgroundColor: '#3A155A',
          // color: 'white',
          ':hover': {
            borderWidth: 2,
            // borderColor: 'white',
            // backgroundColor: '#3A155A',
            // color: 'white',
          },
        },
        text: {
          // textTransform: 'none',
          // ...fonts.Jakarta.Bold,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: 'primary',
        size: 'small',
      },
    },
    MuiPaper: {
      styleOverrides: {
        // this is mui's default elevation and the most commonly used shadow in the app
        elevation1: {
          boxShadow:
            '-8px 4px 16px rgba(66, 107, 240, 0.08), 8px -4px 16px rgba(145, 53, 224, 0.08);',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#919191',
          padding: '7px 7px',
          marginRight: 6,
          '&.Mui-disabled': {
            color: '#D3AEF3',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#919191',
          '&.Mui-disabled': {
            color: '#D3AEF3',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '12px',
          borderRadius: 8,
          border: '1px solid #9135E0',
          background: '#ECF0FE',
        },
      },
    },
  },
}

const nonResponsiveTheme = createTheme(MpThemeOptions)

export const theme = responsiveFontSizes(nonResponsiveTheme)
