import { Container, Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import UserManagementListItem from '../shared/atom/UserManagementListItem'
import MainHeader from '../shared/organism/MainHeader'
import { CorporateActions, InvitedAndActiveEmployeesSelector, ModalOpenSelector, SelectCorporateCode } from '../../redux/corporateService'
import AddEmplyeesModal from '../shared/organism/AddEmployeesModal'
import { useAppDispatch } from '../../redux/storeExports'

const UserManagementView = () => {
  const dispatch=useAppDispatch()
  const open = ModalOpenSelector()
  const corporateCode = SelectCorporateCode()
  const handleOpen = () => dispatch(CorporateActions.setModalOpen(true))
    const filteredEmployees = InvitedAndActiveEmployeesSelector()
  return (
    <Container fixed maxWidth={'lg'} sx={{ mb: 16, mt: '12px' }}>
     <MainHeader title='User Management' count={filteredEmployees.length||0}/>
      <Box mt={5}>
        <Grid container spacing={2} mt={5}>
          <Grid item xs={12} sm={3}>
            <a download="mePrismOnboarding.csv" href="/mePrismOnboarding.csv">
              <Button
                variant={'contained'}
                color={'darkPurple'}
                size={'large'}
                sx={{
                  width: '100%',
                  borderRadius: '10px',
                  boxShadow: 'unset',
                  background: 'transparent',
                  border: '2px solid #C67EFF',
                  mb: 2, 
                  color: '#C67EFF',
                  '&.Mui-disabled': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                  ':hover': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                }}>
                <Typography
                  sx={{
                    color: '#C67EFF',
                    fontSize: '18px',
                    '@media (max-width: 600px)': {
                      fontSize: '14px',
                    },
                  }}>
                  Download CSV Template
                </Typography>
              </Button>
            </a>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <Button
              disabled={!corporateCode}
              variant={'contained'}
              color={'darkPurple'}
              onClick={handleOpen}
              sx={{
                width: '100%',
                borderRadius: '10px',
                boxShadow: 'unset',
                border: '2px solid #C67EFF',
                background: 'transparent',
                color: '#C67EFF',
                '&.Mui-disabled': {
                  background: 'transparent',
                  color: '#C67EFF',
                },
                ':hover': {
                  background: 'transparent',
                  color: '#C67EFF',
                },
              }}>
              Invite Employees
            </Button>
          </Grid>
        </Grid>
        <AddEmplyeesModal open={open}/>
        <Box sx={{ mt: 5 }}>
          <UserManagementListItem />
        </Box>
      </Box>
    </Container>
  )
}

export default UserManagementView
