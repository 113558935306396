import { responsiveFontSizes, ThemeOptions } from '@mui/material/styles'
import { createTheme } from '@mui/material'

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
    body4: true
    body5: true
    title: true
    subheader: true
    label: true
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties
    body4: React.CSSProperties
    body5: React.CSSProperties
    title: React.CSSProperties
    subheader: React.CSSProperties
    label: React.CSSProperties
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties
    body4?: React.CSSProperties
    body5?: React.CSSProperties
    title?: React.CSSProperties
    subheader?: React.CSSProperties
    label?: React.CSSProperties
  }
  // interface Palette {
  //   purple: Palette['grey']
  //   pink: Palette['grey']
  //   cyan: Palette['grey']
  //   green: Palette['grey']
  //   white: Palette['primary']
  //   darkPurple: Palette['primary']
  // }
  // interface PaletteOptions {
  //   purple: PaletteOptions['grey']
  //   pink: PaletteOptions['grey']
  //   cyan: PaletteOptions['grey']
  //   green: PaletteOptions['grey']
  //   darkPurple: PaletteOptions['primary']
  // }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true
    darkPurple: true
  }
}


export const MpReColorPalette = {
  primary: {
    main: '#EFEDFDB2',
    // dark: '#1B2D65',
  },
  secondary: {
    main: '#C67EFF',
  },
  purple: {
    50: '#F4EBFC',
    100: '#E9D7F9',
    200: '#D3AEF3',
    300: '#BD86EC',
    400: '#A75DE6',
    500: '#9135E0',
    600: '#742AB3',
    700: '#572086',
    800: '#3A155A',
    900: '#1D0B2D',
  },
  blue: {
   main:'#426BF0'
  },
  grey: {
    50: '#F9F9F9',
    100: '#E3E3E3',
    200: '#C8C8C8',
    300: '#ACACAC',
    400: '#919191',
    500: '#757575',
    600: '#5E5E5E',
    700: '#464646',
    800: '#2F2F2F',
    900: '#171717',
  },
  pink: {
    50: '#9135E0',
    100: '#F5D2F1',
    200: '#EBA5E3',
    300: '#E177D5',
    400: '#D74AC7',
    500: '#CD1D89',
    600: '#A41794',
    700: '#7B116F',
    800: '#520C4A',
    900: '#290625',
  },
  cyan: {
    50: '#E6F5FE',
    100: '#CCEAFD',
    200: '#99D5FB',
    300: '#66C1F9',
    400: '#33A6F7',
    500: '#2197F5',
    600: '#1A79C4',
    700: '#145B93',
    800: '#0C3C62',
    900: '#031E31',
  },
  green: {
    50: '#e9faf5',
    100: '#D2F5EA',
    200: '#A5EBD6',
    300: '#78E0C1',
    400: '#4BD6AD',
    500: '#1ECC98',
    600: '#18A37A',
    700: '#127A5B',
    800: '#0C523D',
    900: '#06291E',
  },
  white: {
    main: '#ffffff',
  },
  darkPurple: {
    main: '#426BF0',
  },
  background: {
    default: '#1A1B20',
  },
  text: {
    primary: '#3A155A',
    secondary: '#757575',
  },
  divider: '#EDEDED',
  error: {
    main: '#FF3D00',
    light: '#FFF3F0',
    dark: '#541400',
  },
  warning: {
    main: '#E66000',
    light: '#FFF3EB',
    dark: '#5C2600',
  },
  success: {
    main: '#3DA010',
    light: '#ECFFF1',
    dark: '#005418',
  },
  info: {
    main: '#426BF0',
    light: '#F1F4FE',
    dark: '#284090',
  },
} as const

const baseSize = 16
export const px2rem = (size = 16) => {
  return `${size / baseSize}rem`
}

export const Inter = {
  fontFamily: 'Inter',
}

export const fonts = {
  Inter: {
    ExtraLight: {
      // 200
      fontFamily: 'PlusInterExtraLight',
    },
    200: {
      fontFamily: 'PlusInterExtraLight',
    },
    Light: {
      // 300
      fontFamily: 'PlusInterLight',
    },
    300: {
      // 300
      fontFamily: 'PlusInterLight',
    },
    Regular: {
      // 400
      fontFamily: 'InterRegular',
    },
    400: {
      // 400
      fontFamily: 'InterRegular',
    },
    Medium: {
      // 500
      fontFamily: 'InterMedium',
    },
    500: {
      // 500
      fontFamily: 'InterMedium',
    },
    SemiBold: {
      // 600
      fontFamily: 'InterSemiBold',
    },
    600: {
      // 600
      fontFamily: 'InterSemiBold',
    },
    Bold: {
      // 700
      fontFamily: 'InterBold',
    },
    700: {
      // 700
      fontFamily: 'InterBold',
    },
    ExtraBold: {
      // 800
      fontFamily: 'PlusInterExtraBold',
    },
    800: {
      // 800
      fontFamily: 'PlusInterExtraBold',
    },
  },
};
const MpReTheme = {
  fontSize: {
    xlarge: 32,
    large: 24, // UPDATED
    medium: 20,
    small: 16,
    xsmall: 14,
    xxsmall: 12,
    xxxsmall: 10,
  } as const,
  fontStyle: {
    medium: 'medium',
    normal: 'normal',
    bold: 'bold',
    light: 'light',
    regular: 'regular',
    semibold: 'semibold', // ADDED
    extrabold: 'extrabold', // ADDED
  } as const,
  fontWeight: {
    normal: '200',
    thin: '100',
    ultraLight: '200',
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    heavy: '800',
    black: '900',
  } as const,
  colors: {
    secondary: {
      default: '#9135E0',
      600: '#742AB3',
      700: '#572086',
      800: '#3A155A',
      900: '#1D0B2D',
      400: '#A75DE6',
      300: '#BD86EC',
      200: '#D3AEF3',
      100: '#E9D7F9',
      50: '#F4EBFC',
    },
    primary: {
      default: '#426BF0',
      600: '#3556C0',
      700: '#284090',
      800: '#1A2B60',
      900: '#0D1530',
      400: '#6889F3',
      300: '#8EA6F6',
      200: '#B3C4F9',
      100: '#D9E1FC',
      50: '#ECF0FE',
    },
    white: '#FFFFFF',
    gray: {
      default: '#757575',
      600: 'var(--text-colors-stroke, rgba(255, 255, 255, 0.15))',
    },
    tertiary: {
      pink: {
        default: '#CD1D89',
        600: '#A41794',
        700: '#7B116F',
        800: '#520C4A',
        900: '#290625',
        400: '#D74AC7',
        300: '#E177D5',
        200: '#EBA5E3',
        100: '#F5D2F1',
        50: '#FAE8F8',
      },
      cyan: {
        default: '#2197F5',
        600: '#1A79C4',
        700: '#145B93',
        800: '#0C3C62',
        900: '#031E31',
        400: '#33A6F7',
        300: '#66C1F9',
        200: '#99D5FB',
        100: '#CCEAFD',
        50: '#E6F5FE',
      },
      green: {
        default: '#1ECC98',
        600: '#A41794',
        700: '#7B116F',
        800: '#520C4A',
        900: '#290625',
        400: '#4BD6AD',
        300: '#78E0C1',
        200: '#A5EBD6',
        100: '#D2F5EA',
        50: '#E9FAF5',
      },
    },
    informational: { default: '#426BF0', light: '#F1F4FE', dark: '#284090' }, // UPDATED -> Can we do shades like in the web theme?
    success: { default: '#3DA010', light: '#ECFFF1', dark: '#005418' }, // UPDATED -> Can we do shades like in the web theme?
    warning: { default: '#E66000', light: '#FFF3EB', dark: '#5C2600' }, // UPDATED -> Can we do shades like in the web theme?
    error: { default: '#FF3D00', light: '#FFF3F0', dark: '#541400' }, // UPDATED -> Can we do shades like in the web theme?
  },
  gradients: {
    primary: ['#426BF0', '#9135E0'],
    white: ['#ECF0FE', '#FFFFFF'],
    gray: ['#757575', '#DFDFDF'],
    black: ['#464646', '#171717'],
    secondary: ['#73B959', '#93DB79'],
    tertiary: ['#DF3434', '#F33333'],
  },
  fonts: {
    plusJakartaSans: 'PlusJakartaSans-Regular',
    plusJakartaSansRegular: 'PlusJakartaSans-Regular',
    plusJakartaSansSemibold: 'PlusJakartaSans-Semibold',
    plusJakartaSansExtrabold: 'PlusJakartaSans-Extrabold',
    courierNew: 'Courier New',
  } as const,
  spacing: {
    xsmall: 4,
    small: 6,
    medium: 8,
    large: 10,
    xlarge: 12,
    xxlarge: 13,
  },
  border: {
    small: 1,
    medium: 3,
    large: 5,
  },
  lineSpacing: {
    none: 0,
    xxsmall: 0.2,
    xsmall: 0.4,
    small: 0.6,
    medium: 0.8,
    large: 1,
    xlarge: 1.4,
  },
  lineHeight: {
    xsmall: 15,
    small: 18,
    h2: 21,
    medium: 24, // UPDATED
    large: 30, // UPDATED
    xlarge: 40, // UPDATED
  },
  radius: {
    none: 0,
    shadow: 6,
    small: 10,
    medium: 18,
    large: 100,
  },
  layouts: {
    widths: {
      sm: '466px',
      md: '710px',
    },
  },
  Boxwidth:{
    widths: {
      sm: '1100px',
      md: '560px',
    },
  }
}
export default MpReTheme

export const MpReThemeOptions: ThemeOptions = {
  palette: MpReColorPalette,
  spacing: 4,
  breakpoints: {
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1440 },
  },
    typography: {
    fontSize: baseSize,
    ...Inter,
    h1: {
      ...fonts.Inter.Bold,
      fontSize: px2rem(60),
      fontStyle: 'normal',
      fontWeight: 700,
      '@media (max-width: 600px)': {
        fontSize: px2rem(30),
      },
      lineHeight: 75 / 60, // 125%
      letterSpacing: '-2.1px',
      color: '#ffffff', // Set your desired color
    },
        h2: {
      ...fonts.Inter.Bold,
      fontSize: px2rem(26),
      color: '#ffffff',
    },
    h3: {
      ...fonts.Inter.Medium,
      fontSize: 20,
      '@media (max-width: 600px)': {
        fontSize: px2rem(15),
      },
      lineHeight: 22 / 20,
      color: '#ffffff',
    },
    h4: {
      ...fonts.Inter.ExtraBold,
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: 16 / 14,
    },
    h5: {
      ...fonts.Inter.ExtraBold,
      fontSize: 12,
      lineHeight: 14 / 12,
      letterSpacing: 1.4,
    },
        body1: {
      ...fonts.Inter.Regular,
      fontSize: px2rem(14),
      lineHeight: 1.5,
      color: '#ffffff',
    },
    label: {
      fontSize: px2rem(15),
      color: '#ffffff',
    },
    body2: {
      ...fonts.Inter.Medium,
      fontSize: px2rem(16),
      letterSpacing: '0.02rem',
      '@media (max-width: 600px)': {
        fontSize: px2rem(12),
      },
      lineHeight: 1.5,
      color: 'var(--text-colors-light-grey, rgba(239, 237, 253, 0.70))',

    },
    body3: {
      ...fonts.Inter.Regular,
      fontSize: px2rem(14),
      letterSpacing: '0.02rem',
      lineHeight: px2rem(21),
      color:'#ffffff'
    },
    body4: {
      ...fonts.Inter.Regular,
      fontSize: px2rem(10),
      letterSpacing: '0.02rem',
      lineHeight: 1.5,
    },
    body5: {
      ...fonts.Inter.Bold,
      fontSize: px2rem(16),
      letterSpacing: '0.02rem',
      lineHeight: 1.5,
      color:'#ffffff'
    },
    caption: {
      ...fonts.Inter.Regular,
      fontSize: px2rem(16),
      color: '#ffffff',
      lineHeight: 17 / 14,
    },
  },
 
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          // fontWeight: 700,
          // ':disabled': {
          //   backgroundColor: '#3A155A',
          //   opacity: 0.5,
          //   color: '#ffffff',
          // },
      },
        root: {
          borderRadius: '8px',
          background: '#426BF0',
          ...fonts.Inter.SemiBold,
          color:'#ffffff',
          '@media (max-width: 600px)': {
            fontSize: px2rem(14),
          },
          '@media (min-width: 601px)': {
            fontSize: px2rem(18),
          },
          textTransform: 'none',
          '&.Mui-disabled': {
              background: '#426BF0',
              color: '#ffffff',
          },
          '&:hover': {
            background: '#426BF0', 
          },
          '&.Mui-active': {
            background: '#426BF0', 
            color:'#ffffff'
          },
        },
        textSizeMedium: {
          padding: '6px 16px',
        },
        outlined: {
          borderWidth: 2,
          borderColor: 'white',
          // backgroundColor: '#3A155A',
          // color: 'white',
          ':hover': {
            borderWidth: 2,
            borderColor: 'white',
            backgroundColor: '#426BF0',
            color: 'white',
          },
        },
        
        text: {
          textTransform: 'none',
          ...fonts.Inter.SemiBold,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
        size: 'small',
      },
      styleOverrides: {
        root: {
          border: '1px solid #EFEDFDB2',
          background: '#1A1B20',
          borderRadius: '10px',
          '&:hover': {
            borderColor: '#EFEDFDB2',
          },
          '&:focus': {
            borderColor: '#EFEDFDB2',
          },
        },
      },
    },
    MuiTab:{
      styleOverrides:{
        root: {
          color:'#EFEDFDB2',
          borderBottom: '3px solid #EFEDFDB2',
          fontSize: px2rem(14),
          '@media (max-width: 600px)': {
            fontSize: px2rem(12),
          },
          fontFamily:'InterSemiBold',
          textTransform: 'none',
          '&.Mui-selected': { color: 'secondary.main' } 
        },
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          height: 50,
          color: '#EFEDFDB2',
          cursor:'pointer',
          border: '1px solid #EFEDFDB2',
          borderRadius: '10px',
          '&:hover': {
            borderColor: '#EFEDFDB2',
          },
          '&:focus': {
            borderColor: '#EFEDFDB2',
          },
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px #1A1B20 inset',
              WebkitTextFillColor: '#EFEDFDB2',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
        color: '#EFEDFDB2',
        '&$checked': {
          color: '#EFEDFDB2',
        },
      },
    },
    },
    MuiLink:{
      styleOverrides:{
        root: {
          '@media (max-width: 600px)': {
            fontSize: px2rem(10),
          },
          color: '#6F8EF5',
          textDecoration: 'underline', 
          '&:hover': {
            textDecoration: 'underline',
          },
          '&:focus': {
            textDecoration: 'underline',
          },
        },
      }
    }
  },
  
}

const nonResponsiveTheme = createTheme(MpReThemeOptions)

export const theme = responsiveFontSizes(nonResponsiveTheme)